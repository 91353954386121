import React from 'react'

import Divider from '@mui/material/Divider'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Zoom from '@mui/material/Zoom'
import Paper from '@mui/material/Paper'
import FormatAlignLeft from '@mui/icons-material/FormatAlignLeft'
import FormatAlignCenter from '@mui/icons-material/FormatAlignCenter'
import FormatAlignRight from '@mui/icons-material/FormatAlignRight'
import FormatBold from '@mui/icons-material/FormatBold'
import FormatItalic from '@mui/icons-material/FormatItalic'
import FormatUnderlined from '@mui/icons-material/FormatUnderlined'
import FormatStrikethrough from '@mui/icons-material/FormatStrikethrough'
import FormatTextdirectionRToLTwoTone from '@mui/icons-material/FormatTextdirectionRToLTwoTone'

import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  divider: {
    margin: theme.spacing(1, 0.5),
  },
}))

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup)

interface TextButtonsProps {
  show: boolean
  alignment: string
  formats: string[]
  onChange: (options: { alignment: string; formats: string[] }) => void
}

const TextButtons = ({ onChange, show, alignment, formats }: TextButtonsProps) => {
  const handleFormat = (_e: React.MouseEvent<HTMLElement>, newFormats: string[]) => {
    onChange({ alignment, formats: newFormats })
  }

  const handleAlignment = (_e: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    onChange({ alignment: newAlignment, formats })
  }

  const classes = useStyles()
  return (
    <Zoom in={Boolean(show)}>
      <div>
        <Paper elevation={4} className={classes.paper}>
          <StyledToggleButtonGroup
            size="small"
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton value="justify-left" aria-label="left aligned">
              <FormatAlignLeft />
            </ToggleButton>
            <ToggleButton value="justify-center" aria-label="centered">
              <FormatAlignCenter />
            </ToggleButton>
            <ToggleButton value="justify-right" aria-label="right aligned">
              <FormatAlignRight />
            </ToggleButton>
          </StyledToggleButtonGroup>
          <Divider flexItem orientation="vertical" className={classes.divider} />
          <StyledToggleButtonGroup
            size="small"
            value={formats}
            onChange={handleFormat}
            aria-label="text formatting"
          >
            <ToggleButton value="bold" aria-label="bold">
              <FormatBold />
            </ToggleButton>
            <ToggleButton value="italic" aria-label="italic">
              <FormatItalic />
            </ToggleButton>
            <ToggleButton value="underline" aria-label="underline">
              <FormatUnderlined />
            </ToggleButton>
            <ToggleButton value="linethrough" aria-label="linethrough">
              <FormatStrikethrough />
            </ToggleButton>
            <ToggleButton value="overline" aria-label="overline">
              <FormatTextdirectionRToLTwoTone />
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Paper>
      </div>
    </Zoom>
  )
}

export default TextButtons
