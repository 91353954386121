import makeAuthApi from '@vitrical/vitrical-auth-api'

const baseUrl =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3001/api/auth' : '/api/auth'

const AuthApi = makeAuthApi({
  url: baseUrl,
  key: 'FiwNA129D81nDU12fSA',
})

export default AuthApi
