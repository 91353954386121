import React from 'react'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

import contactUsArt from '../../assets/contact-photo.png'
import Link from '@mui/material/Link'
import { MEETING_LINK } from '../../util/Api'

const SuperContainer = styled('div')({
  color: '#ffffff',
})

const ImageContainer = styled('div')({
  maxWidth: '450px',
})

const Image = styled('img')({
  width: '100%',
  height: 'auto',
})

const ContactSuper = () => {
  return (
    <SuperContainer>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        padding={2}
        spacing={4}
        sx={{ pt: 12 }}
      >
        <Grid item>
          <Typography variant="h2" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="h5" sx={{ color: '#e6e6e6' }}>
            We're always ready to chat
          </Typography>
        </Grid>
        <Grid item>
          <ImageContainer>
            <Image src={contactUsArt} alt="contact-us-art" />
          </ImageContainer>
        </Grid>
      </Grid>
    </SuperContainer>
  )
}

export default ContactSuper
