import React, { useState } from 'react'
import { useNavigate } from 'react-router'

import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { NavLocation } from './Navbar'

type MobileMenuProps = {
  locations: NavLocation[]
}

const MobileMenu = ({ locations }: MobileMenuProps) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleNav = (path: string) => {
    setAnchorEl(null)
    navigate(path)
  }

  return (
    <>
      <IconButton
        id="menu-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
        sx={{ color: '#ffffff' }}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleNav('/')}>Home</MenuItem>
        {locations.map((location) => (
          <MenuItem key={location.name} onClick={() => handleNav(location.path)}>
            {location.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default MobileMenu
