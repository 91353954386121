import React from 'react'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

import Link from '@mui/material/Link'
import { useNavigate } from 'react-router-dom'

const SuperContainer = styled('div')({
  color: '#ffffff',
})

const ConfirmSuper = () => {
  const navigate = useNavigate()

  const onClickNav = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault()
    navigate('/')
  }

  return (
    <SuperContainer>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        padding={2}
        spacing={4}
        sx={{ pt: 20 }}
      >
        <Grid item>
          <Typography variant="h2" textAlign="center" sx={{ mb: 2 }}>
            Thank you for the request
          </Typography>
          <Typography variant="h4" textAlign="center" sx={{ color: '#d1d1d1' }}>
            We will get back to you as soon as we can
          </Typography>
          <Grid container spacing={1} justifyContent="center" sx={{ mt: 8 }}>
            <Grid item>
              <Button
                variant="contained"
                fullWidth
                onClick={onClickNav}
                disableElevation
                color="info"
              >
                <Link
                  color="primary.contrastText"
                  sx={{ textDecoration: 'none' }}
                  href="/"
                  onClick={onClickNav}
                >
                  Go back to home
                </Link>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SuperContainer>
  )
}

export default ConfirmSuper
