import React from 'react'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { MEETING_LINK } from '../../util/Api'

const QuoteText = () => {
  return (
    <Container>
      <Grid container alignItems="center" justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ mb: 1 }} textAlign="center">
            Ready to see what we can do?
          </Typography>
          <Typography variant="subtitle1" textAlign="center">
            Get a free 15 minute consultation.
          </Typography>
        </Grid>
        <Grid item xs={12} sm="auto" sx={{ mt: 3 }}>
          <Button variant="contained" fullWidth LinkComponent={Link} href={MEETING_LINK}>
            Book a Demo
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export default QuoteText
