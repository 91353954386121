import { makeMicroserviceRequest } from '@vitrical/utils/api'

const DEV_URL = 'http://localhost:3001'
const PROD_URL = ''

const baseUrl = process.env.NODE_ENV === 'development' ? DEV_URL : PROD_URL

export const clientRequest = makeMicroserviceRequest(baseUrl, 'FiwNA129D81nDU12fSA')

export const MEETING_LINK = 'https://calendly.com/elijah-lipsky/v15'

type MsgResponse = {
  msg: string
}

type GetCompanyInfoResponse = MsgResponse & {
  email: string
  phone: string
}

export const getCompanyInfo = (): Promise<GetCompanyInfoResponse> =>
  clientRequest('get', '/api/info')

type GetGoogleMapInfoResponse = MsgResponse & {
  key: string
}

export const getGoogleMapInfo = (): Promise<GetGoogleMapInfoResponse> =>
  clientRequest('get', '/api/info/google')

export type ChatHistory = {
  text: string
  client: boolean
  createdAt: number
}

type InitChatOptions = {
  history: ChatHistory[]
}

type InitChatRespnose = MsgResponse & {
  chatId: string
}

export const initChat = (options: InitChatOptions): Promise<InitChatRespnose> =>
  clientRequest('put', '/api/chat', { body: options })

type RefreshChatOptions = {
  chatId: string
  typing: boolean
  token?: string
}

type RefreshChatResponse = MsgResponse & {
  history: ChatHistory[]
  adminTypingAt: number
  clientTypingAt: number
  lastClientInteraction: number
  lastAdminInteraction: number
}

export const refreshChat = ({
  chatId,
  typing,
  token,
}: RefreshChatOptions): Promise<RefreshChatResponse> =>
  clientRequest('get', `/api/chat/${encodeURIComponent(chatId)}`, {
    query: { typing },
    headers: token ? { authorization: token } : {},
  })

type SendChatOptions = {
  chatId: string
  text: string
  token?: string
}

type SendChatResponse = MsgResponse & {
  history: ChatHistory[]
  adminTypingAt: number
  clientTypingAt: number
  lastClientInteraction: number
  lastAdminInteraction: number
}

export const sendChat = ({ chatId, text, token }: SendChatOptions): Promise<SendChatResponse> =>
  clientRequest('post', `/api/chat/${encodeURIComponent(chatId)}`, {
    body: { text },
    headers: token ? { authorization: token } : {},
  })

type DeleteChatOptions = {
  chatId: string
  token: string
}

type DeleteChatResponse = MsgResponse

export const deleteChat = ({ chatId, token }: DeleteChatOptions): Promise<DeleteChatResponse> =>
  clientRequest('delete', `/api/chat/${encodeURIComponent(chatId)}`, {
    headers: { authorization: token },
  })

type GetChatsOptions = {
  token: string
}

export type Chat = {
  id: string
  ip: string
  clientTypingAt: number
  adminTypingAt: number
  lastClientInteraction: number
  lastAdminInteraction: number
  history: ChatHistory[]
}

type GetChatsResponse = MsgResponse & {
  chats: Chat[]
}

export const getChats = ({ token }: GetChatsOptions): Promise<GetChatsResponse> =>
  clientRequest('get', '/api/chat', {
    headers: { authorization: token },
  })

type GuideFormResponse = MsgResponse

type GuideFromOptions = {
  firstName: string
  lastName: string
  email: string
  phone: string
  newsletter: boolean
}

export const submitGuideForm = (options: GuideFromOptions): Promise<GuideFormResponse> =>
  clientRequest('post', '/api/info/guide', { body: options })
