import styled from '@mui/system/styled'

const Column = styled('div')({
  paddingLeft: '3vw',
  paddingRight: '3vw',
  paddingTop: '2vh',
  paddingBottom: '2vh',
})

export default Column
