import React from 'react'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import styled from '@mui/system/styled'
import introPhoto from '../../assets/intro-photo.png'

const IntroImage = styled('img')({
  width: '100%',
  height: 'auto',
})

const IntroText = () => {
  return (
    <Container>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <IntroImage src={introPhoto} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" sx={{ mb: 3 }}>
            Our team, your app
          </Typography>
          <Typography variant="subtitle1">
            With our specialized team for development, QA, and project management, We’ll take the
            responsibility of handling your large projects, while leaving all the control up to you.
            We develop while you lead.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default IntroText
