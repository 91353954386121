import React, { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import styled from '@mui/system/styled'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import Link from '@mui/material/Link'

import MobileMenuButton from './MobileMenuButton'

import LogoBanner from '../../assets/dark-logo-banner.png'
import { alpha, Box } from '@mui/system'

export const leadButtonText = 'Free Tech Guide'

interface NavbarComponentProps {
  small?: boolean
}

const NavbarComponent = styled('nav', {
  shouldForwardProp: (prop) => prop !== 'small',
})<NavbarComponentProps>(({ small }) => ({
  width: '100%',
  position: 'absolute',
  top: 0,
  // Apply linear gradient to navbar background
  background: !small
    ? 'linear-gradient(90deg, #4b187c 0%, #671d9a 100%)'
    : 'linear-gradient(90deg, #4b187c 0%, #671d9a 100%)',
}))

const LogoContainer = styled('div')`
  width: 100%;
  height: 60px;
  padding: 8px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  user-select: none;
`

export type NavLocation = {
  name: string
  path: string
}

const locations: NavLocation[] = [
  {
    name: 'Examples',
    path: '/examples',
  },
  {
    name: 'Contact Us',
    path: '/contact',
  },
  {
    name: 'About Us',
    path: '/process',
  },
  {
    name: 'Terms of Service',
    path: '/legal',
  },
]

const Navbar = () => {
  //Can just adjust sm media min-width to 814px if a hamburger menu is preferred.
  const sm = useMediaQuery('(min-width:730px)')
  const location = useLocation()
  const navigate = useNavigate()

  const handleNav = (
    e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement>,
    path: string
  ) => {
    e.preventDefault()
    navigate(path)
  }

  const getPathname = useCallback(() => {
    return location.pathname
  }, [location.pathname])

  return (
    <>
      <NavbarComponent small={!sm}>
        {!sm && (
          <Box
            sx={(theme) => ({
              backgroundColor: alpha(theme.palette.primary.dark, 0.4),
            })}
          >
            <Grid container justifyContent="space-between" alignItems="center" padding={1}>
              <Grid item>
                <MobileMenuButton locations={locations} />
              </Grid>
              <Grid item>
                <Button
                  color="info"
                  disableElevation
                  variant="contained"
                  href="/guide"
                  onClick={(e) => handleNav(e, '/guide')}
                >
                  {leadButtonText}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
        {sm && (
          <Grid container justifyContent="space-evenly" alignItems="center" flexWrap="nowrap">
            <Grid item>
              <LogoContainer onClick={(e) => handleNav(e, '/')}>
                <img
                  draggable={false}
                  src={LogoBanner}
                  style={{
                    width: 'auto',
                    height: '100%',
                  }}
                  alt="logo"
                />
              </LogoContainer>
            </Grid>
            {locations.map((location) => (
              <Grid item key={location.name}>
                <Button
                  color="secondary"
                  variant="text"
                  onClick={(e) => handleNav(e, location.path)}
                >
                  <Link
                    color="secondary"
                    sx={
                      getPathname() !== location.path
                        ? { textDecoration: 'none', color: '#ffffff' }
                        : { textDecoration: 'none' }
                    }
                    href={location.path}
                    onClick={(e) => handleNav(e, location.path)}
                  >
                    {location.name}
                  </Link>
                </Button>
              </Grid>
            ))}
            <Grid item>
              <Button
                color="info"
                disableElevation
                variant="contained"
                href="/guide"
                onClick={(e) => handleNav(e, '/guide')}
              >
                {leadButtonText}
              </Button>
            </Grid>
          </Grid>
        )}
      </NavbarComponent>
    </>
  )
}

export default Navbar
