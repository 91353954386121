import React from 'react'

import useMediaQuery from '@mui/material/useMediaQuery'

import Grid from '@mui/material/Grid'

import ExampleUI from '../components/examples/ExampleUI'
import ExampleFabric from '../components/examples/ExampleFabric'
import ExampleDatabase from '../components/examples/ExampleDatabase'
import GradientBackground from '../components/layouts/GradientBackground'
import PlaygroundSuper from '../components/layouts/PlaygroundSuper'
import PricingPlaygroundLink from '../components/layouts/PricingPlaygroundLink'
import ExampleMap from '../components/examples/ExampleMap'

const Playground = () => {
  const sm = useMediaQuery('(min-width:993px)')

  return (
    <div>
      <GradientBackground small={!sm}>
        <PlaygroundSuper />
      </GradientBackground>
      <Grid container justifyContent="center" padding={1}>
        <Grid item xs={12} sm={12} lg={10}>
          <ExampleUI />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" padding={1}>
        <Grid item xs={12} sm={12} lg={10}>
          <ExampleDatabase />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" padding={1}>
        <Grid item xs={12} sm={12} lg={10}>
          <ExampleMap />
        </Grid>
      </Grid>
      {sm && (
        <Grid container justifyContent="center" padding={1}>
          <Grid item xs={12} sm={12} lg={10}>
            <ExampleFabric />
          </Grid>
          {/* <Grid>
            <PricingPlaygroundLink />
          </Grid> */}
        </Grid>
      )}
    </div>
  )
}

export default Playground
