import Box from '@mui/material/Box'
import styled from '@mui/material/styles/styled'

type AppearingBoxProps = {
  show: boolean
  delay: number
  showOnMobile?: boolean
}

const AppearingBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'delay' && prop !== 'show' && prop !== 'showOnMobile',
})<AppearingBoxProps>(({ theme, delay, show, showOnMobile }) => ({
  [showOnMobile ? theme.breakpoints.up('xs') : theme.breakpoints.up('sm')]: {
    animation: show ? 'fadeIn 0.8s ease-in-out' : 'none',
    opacity: 0,
    animationDelay: delay + 'ms',
    animationFillMode: 'forwards',
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(20px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0px)',
    },
  },
}))

export default AppearingBox
