import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import GoogleLogo from './GoogleLogo'

const GoogleLoginButton = (props: Omit<ButtonProps, 'children'>) => {
  return (
    <Button
      startIcon={<GoogleLogo />}
      variant="contained"
      color="inherit"
      sx={{ fontWeight: 500 }}
      {...props}
    >
      Login With Google
    </Button>
  )
}

export default GoogleLoginButton
