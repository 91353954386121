import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'

import privacyPolicyFile from '../assets/privacy-policy.txt'
import termsOfServiceFile from '../assets/terms-of-service.txt'

function textToHTML(text: string): string {
  let lines = text.split('\n')
  let inList = false

  for (let i = 0; i < lines.length; i++) {
    let indent = lines[i].search(/\S|$/)

    // Convert double spaces to HTML tab
    if (lines[i].startsWith('  ') && !lines[i].trim().startsWith('- ')) {
      lines[i] = "<p style='margin-left:" + indent / 2 + "em;'>" + lines[i].trim() + '</p>'
    } else if (lines[i].trim().startsWith('- ')) {
      lines[i] =
        "<li style='margin-left:" +
        indent / 2 +
        "em;'>" +
        lines[i].substring(indent + 2).trim() +
        '</li>'
      if (!inList) {
        lines[i] = '<ul>' + lines[i]
        inList = true
      }
    } else {
      if (inList) {
        lines[i - 1] = lines[i - 1] + '</ul>'
        inList = false
      }
      lines[i] = '<p>' + lines[i] + '</p>'
    }

    // URLs to anchor tags
    lines[i] = lines[i].replace(/(https?:\/\/[^\s]+)/g, '<a href="$1">$1</a>')

    // Emails to mailto links
    lines[i] = lines[i].replace(
      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi,
      '<a href="mailto:$1">$1</a>'
    )

    // Text wrapped in ** to bold
    lines[i] = lines[i].replace(/\*\*(.+?)\*\*/g, '<b>$1</b>')
  }
  if (inList) {
    lines[lines.length - 1] = lines[lines.length - 1] + '</ul>'
  }
  return lines.join('')
}

const PrivacyPolicy = () => {
  // Grab the query param redirectUri from the url
  const location = window.location
  const searchParams = new URLSearchParams(location.search)
  const redirectUri = searchParams.get('redirectUri')

  const [privacyPolicyText, setPrivacyPolicyText] = useState('')
  const [termsOfServiceText, setTermsOfServiceText] = useState('')

  useEffect(() => {
    fetch(privacyPolicyFile)
      .then((r) => r.text())
      .then((text) => {
        setPrivacyPolicyText(text)
      })
    fetch(termsOfServiceFile)
      .then((r) => r.text())
      .then((text) => {
        setTermsOfServiceText(text)
      })
  }, [])

  const handleAgree = () => {
    if (!redirectUri) return
    window.location.href = redirectUri
  }

  return (
    <>
      <Box sx={(theme) => ({ height: 64, width: '100%', mb: 4 })} />
      <Container maxWidth="md">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h4" component="h1" sx={{ mb: 4 }}>
              Terms of Service
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {/** Dangerously set html based on privacy policy text */}
              <div dangerouslySetInnerHTML={{ __html: textToHTML(termsOfServiceText) }} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" component="h1" sx={{ mb: 4 }}>
              Privacy Policy
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {/** Dangerously set html based on privacy policy text */}
              <div dangerouslySetInnerHTML={{ __html: textToHTML(privacyPolicyText) }} />
            </Typography>
          </Grid>
          {redirectUri && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm="auto">
                <Typography
                  variant="body1"
                  fontWeight={500}
                  sx={{ mb: 2, mt: 4 }}
                  textAlign="center"
                >
                  By clicking "I Agree" you are agreeing to the above Privacy Policy.
                </Typography>
                <Button variant="contained" fullWidth onClick={handleAgree}>
                  I Agree
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </>
  )
}

export default PrivacyPolicy
