import React from 'react'

import GradientBackground from '../components/layouts/GradientBackground'
import useMediaQuery from '@mui/material/useMediaQuery'
import ConfirmSuper from '../components/layouts/ConfirmSuper'

const Confirm = () => {
  const sm = useMediaQuery('(min-width:993px)')

  return (
    <>
      <GradientBackground small={!sm}>
        <ConfirmSuper />
      </GradientBackground>
    </>
  )
}

export default Confirm
