import Grid from '@mui/material/Grid'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'

import ArrowUpward from '@mui/icons-material/ArrowUpward'

import styled from '@mui/material/styles/styled'

import Paper from '@mui/material/Paper'
import MemberCard, { Member } from './MemberCard'

const MemberPaper = styled(Paper)`
  min-width: 300px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    min-width: 220px;
  }
`

interface MemberListProps {
  list: Member[]
  active?: boolean
  onDelete?: (member: Member) => void
  onAdmin?: (member: Member) => void
  onMove?: (member: Member) => void
  onSort?: () => void
  onChangeAge?: (member: Member, value: string) => void
  onChangeRole?: (member: Member, value: string) => void
  color?: boolean
  elevate?: boolean
}

const MemberList = ({
  list = [],
  active = false,
  onDelete = () => {},
  onAdmin = () => {},
  onMove = () => {},
  onSort = () => {},
  onChangeAge = () => {},
  onChangeRole = () => {},
  color = false,
  elevate = false,
}: MemberListProps) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <MemberPaper>
      <AppBar
        elevation={elevate ? 4 : 0}
        position="static"
        color={color ? 'primary' : 'secondary'}
        sx={{ borderTopLeftRadius: 3, borderTopRightRadius: 3 }}
      >
        <Toolbar variant="dense">
          <Grid container direction="row" justifyContent="space-between">
            <Grid item alignSelf="center">
              <Typography variant="h6">{active ? 'Active Users' : 'Inactive Users'}</Typography>
            </Grid>
            <Grid item alignSelf="center">
              <Tooltip title="Bring admins to top" placement="right">
                <IconButton
                  size={sm ? 'small' : 'medium'}
                  aria-label="admins-to-top"
                  color="inherit"
                  onClick={onSort}
                >
                  <ArrowUpward />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box sx={{ overflow: 'scroll', height: '550px' }}>
        <Grid container direction="column">
          {list.map((member) => {
            return (
              <Grid key={member.name} item style={{ margin: 12 }}>
                <MemberCard
                  elevate={elevate}
                  color={color}
                  active={active}
                  member={member}
                  onMove={() => onMove(member)}
                  onDelete={() => onDelete(member)}
                  onAdmin={() => onAdmin(member)}
                  onChangeAge={(val) => onChangeAge(member, val)}
                  onChangeRole={(val) => onChangeRole(member, val)}
                />
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </MemberPaper>
  )
}

export default MemberList
