import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Fab from '@mui/material/Fab'
import ChatIcon from '@mui/icons-material/Chat'
import Popover from '@mui/material/Popover'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import CloseIcon from '@mui/icons-material/Close'
import Badge, { BadgeProps } from '@mui/material/Badge'
import styled from '@mui/material/styles/styled'
import TextField from '@mui/material/TextField'
import SendIcon from '@mui/icons-material/Send'
import IconButton from '@mui/material/IconButton'
import Cookies from 'js-cookie'
import { ChatHistory, initChat, refreshChat, sendChat } from '../../util/Api'
import sendMsgFile from '../../assets/sendmsg.mp3'
import { isRecent } from '../../util/Func'
import ChatTypingBubbles from '../ChatTypingBubbles'
import useMediaQuery from '@mui/material/useMediaQuery'
import eliAvatar from '../../assets/eli-avatar.jpg'

export const chatNameText = 'Vitrical Support'
export const textfieldPlaceholderText = 'Reply to Elijah Lipsky'
export const starterTexts = [
  'Hi! Is there anything I can help you with?',
  'Hi, how are you doing today?',
  'Hey there. How can I help you today?',
  'Hi, how can I help you today?',
  'Hi, is there any questions I can answer for you?',
]
export const startupDelayInterval = [6600, 12300]

interface StyledBadgeProps extends BadgeProps {
  active: boolean
}

const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'active',
})<StyledBadgeProps>(({ theme, active }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: !active ? '#929292' : '#44b700',
    color: !active ? '#929292' : '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: !active ? undefined : 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))

const ResponderAvatar = (props: StyledBadgeProps) => {
  return (
    <StyledBadge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
      {...props}
    >
      <Avatar alt={chatNameText} src={eliAvatar} />
    </StyledBadge>
  )
}

interface ChatRowProps {
  client: boolean
  text: string
  showName: boolean
}

const ChatRow = ({ client, text, showName }: ChatRowProps) => {
  return (
    <Grid container justifyContent={client ? 'end' : 'start'} padding={1}>
      <Grid item xs={12} sm={8}>
        <Grid
          container
          spacing={1}
          alignItems="end"
          justifyContent={client ? 'end' : 'start'}
          flexWrap="nowrap"
        >
          {!client && (
            <Grid
              item
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              })}
            >
              <Avatar alt={chatNameText} src={eliAvatar} />
            </Grid>
          )}
          <Grid item xs>
            {!client && showName && (
              <Typography display="block" variant="caption" gutterBottom color="text.secondary">
                {chatNameText}
              </Typography>
            )}
            <Grid container justifyContent={client ? 'end' : 'start'}>
              <Grid item>
                <ChatBubble colored={!client} text={text} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

interface ChatBubbleProps {
  text: string
  colored: boolean
}

const ChatBubble = ({ text, colored }: ChatBubbleProps) => {
  return (
    <Box
      sx={(theme) => ({
        bgcolor: !colored ? theme.palette.primary.main : theme.palette.primary.light,
        color: !colored ? theme.palette.primary.contrastText : theme.palette.text.primary,
        p: 1,
        borderRadius: theme.spacing(2),
        paddingLeft: 2,
        paddingRight: 2,
        overflow: 'hidden',
      })}
    >
      <Typography style={{ wordWrap: 'break-word' }} variant="body2" fontWeight={500}>
        {text}
      </Typography>
    </Box>
  )
}

const ChatOverlay = () => {
  const sm = useMediaQuery('(max-width:600px)')

  const sendMsgAudio = useMemo(() => new Audio(sendMsgFile), [])
  const anchorEl = useRef<null | HTMLButtonElement>(null)
  const chatBoxEl = useRef<HTMLDivElement>(null)

  const [startDate] = useState(new Date())
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState('')
  const [chatId, setChatId] = useState('')
  const [chatUpdatedAt, setChatUpdatedAt] = useState(0)
  const [adminTypingAt, setAdminTypingAt] = useState(0)
  const [lastAdminInteraction, setLastAdminInteraction] = useState(new Date().getTime())

  // Use reducer to only update chat history if it has changed (to prevent audio from playing on every render)
  const [chatHistory, setChatHistory] = useReducer<
    (state: ChatHistory[], action: ChatHistory[]) => ChatHistory[]
  >((state, action) => {
    if (state.length !== action.length) {
      if (action[action.length - 1] && !action[action.length - 1].client) {
        try {
          sendMsgAudio.play()
        } catch (e) {
          console.error(e)
        }
      }
      return action
    }
    return state
  }, [])

  // Get token from cookies if it exists
  const token = Cookies.get('token')

  // Check if chat is stale (no admin interaction in 5 minutes)
  const isChatStale = () => {
    // If latest message is from admin, chat is not stale
    if (chatHistory[chatHistory.length - 1] && !chatHistory[chatHistory.length - 1].client) {
      return false
    }
    const now = new Date().getTime()
    return now - lastAdminInteraction > 1000 * 60 * 5
  }

  // Handle scroll when chat is opened
  useEffect(() => {
    if (!open) return
    scrollBox(true)
  }, [open])

  // Check if chat is scrolled to bottom
  const isAtScrollBottom = () => {
    const chatBox = chatBoxEl.current
    if (chatBox) {
      return chatBox.scrollHeight - chatBox.scrollTop === chatBox.clientHeight
    }
    return false
  }

  // Scroll chat to bottom
  const scrollBox = (wasAtScrollBottom: boolean) => {
    setTimeout(() => {
      const chatBox = chatBoxEl.current
      if (chatBox) {
        if (wasAtScrollBottom) {
          chatBox.scrollTop = chatBox.scrollHeight
        }
      }
    }, 100)
  }

  const handleChangeMsg = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMsg(e.target.value)
  }

  const handleClickChat = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(!open)
  }

  // Initialize chat with prepopulated message
  const initializeChat = async (text: string) => {
    try {
      setLoading(true)
      const newHistory = {
        text,
        client: true,
        createdAt: new Date().getTime(),
      }
      const res = await initChat({
        history: [...chatHistory, newHistory],
      })
      setChatId(res.chatId)
      setChatHistory([...chatHistory, newHistory])
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const sendChatMsg = async (text: string) => {
    try {
      setLoading(true)
      const res = await sendChat({
        chatId,
        text,
      })
      const atScrollBottom = isAtScrollBottom()
      setChatHistory(res.history)
      scrollBox(atScrollBottom)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  // Handle initialize chat or send chat message depending on chatId state
  const handleSendChat = () => {
    if (!msg) return
    if (!chatId) {
      initializeChat(msg)
    } else {
      sendChatMsg(msg)
    }
    setMsg('')
  }

  // Handle send by form submit
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSendChat()
  }

  // Handle send by button click
  const handleClickSendChat = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    handleSendChat()
  }

  // Handle populating default chat message after startup delay
  useEffect(() => {
    if (token) return
    if (chatId) return
    if (chatHistory.length !== 0) return
    const timeout = setTimeout(() => {
      if (chatId) return
      if (chatHistory.length !== 0) return
      if (!sm) {
        setOpen(true)
      }
      setAdminTypingAt(new Date().getTime())

      setTimeout(() => {
        if (chatId) return
        setAdminTypingAt(0)
        setChatHistory([
          {
            text: starterTexts[Math.round(Math.random() * (starterTexts.length - 1))],
            client: false,
            createdAt: new Date().getTime(),
          },
        ])
      }, Math.random() * 1000 + 2000)
    }, (startupDelayInterval[1] - startupDelayInterval[0]) * Math.random() + startupDelayInterval[0])
    return () => {
      clearTimeout(timeout)
    }
  }, [sendMsgAudio, token, chatId, sm, chatHistory])

  // Is client typing?
  const typing = !!msg

  // Refresh chat and get latest messages and chat status
  const updateChat = useCallback(async () => {
    try {
      const atScrollBottom = isAtScrollBottom()
      const res = await refreshChat({ chatId, typing })
      setChatHistory(res.history)
      setAdminTypingAt(res.adminTypingAt)
      scrollBox(atScrollBottom)
      setLastAdminInteraction(res.lastAdminInteraction)
    } catch (err) {
      if (typeof err === 'object' && !!err && 'status' in err && err.status === 404) {
        setChatId('')
        setChatHistory([])
      }
      console.error(err)
    } finally {
      setChatUpdatedAt(new Date().getTime())
    }
  }, [chatId, typing])

  // Periodically refresh chat
  useEffect(() => {
    if (token) return
    if (!chatId) return
    const timeout = setTimeout(() => {
      updateChat()
    }, 2000)
    return () => clearTimeout(timeout)
  }, [chatId, updateChat, chatUpdatedAt, token])

  // Amount of new messages not authored by client
  const newestMessageCount = useMemo(() => {
    let count = 0
    for (let i = chatHistory.length - 1; i >= 0; i--) {
      const message = chatHistory[i]
      if (message.client) {
        break
      }
      count++
    }
    return count
  }, [chatHistory])

  // Whether to show name of client or admin based on message index
  const shouldShowName = (index: number) => {
    if (index === 0) return true
    const prevMessage = chatHistory[index - 1]
    const message = chatHistory[index]
    return prevMessage.client !== message.client
  }

  const handlePopoverClose = (_e: {}, reason: string) => {
    setOpen(false)
    if (reason === 'backdropClick') {
      return
    }
  }

  if (token) {
    return <></>
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        position: 'fixed',
        bottom: 0,
        left: 0,
        pointerEvents: 'none',
      }}
    >
      <Grid
        container
        justifyContent="end"
        alignContent="end"
        sx={{ height: '100%' }}
        padding={{ xs: 1, sm: 4 }}
      >
        <Grid item>
          <Badge
            overlap="circular"
            badgeContent={newestMessageCount || undefined}
            color="success"
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <Fab
              color="primary"
              sx={{ pointerEvents: 'all' }}
              onClick={handleClickChat}
              ref={anchorEl}
            >
              {open ? <CloseIcon /> : <ChatIcon />}
            </Fab>
          </Badge>
        </Grid>
      </Grid>
      <Popover
        id="chat-menu"
        onClose={handlePopoverClose}
        anchorEl={anchorEl.current}
        open={open}
        disablePortal
        disableScrollLock
        elevation={24}
        marginThreshold={2}
        anchorOrigin={{
          vertical: -20,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            pointerEvents: 'all',
          },
        }}
        disableAutoFocus
        disableEnforceFocus
      >
        <Box
          sx={(theme) => ({
            [theme.breakpoints.up('xs')]: {
              width: '100%',
            },
            [theme.breakpoints.up('sm')]: {
              width: 250,
            },
            [theme.breakpoints.up('md')]: {
              width: 340,
            },
          })}
        >
          <Box
            sx={(theme) => ({
              bgcolor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            })}
          >
            <Toolbar>
              <ResponderAvatar active={!isChatStale()} sx={{ mr: 2 }} />
              <Typography variant="subtitle1" fontWeight={500} noWrap>
                {chatNameText}
              </Typography>
            </Toolbar>
          </Box>
          {!sm && (
            <Typography variant="body2" textAlign="center" color="text.secondary" sx={{ p: 1 }}>
              {startDate.toDateString()}
            </Typography>
          )}

          <Box
            width="100%"
            p={1}
            height={sm ? 'calc(80vh - 200px)' : '320px'}
            sx={{ overflowY: 'auto' }}
            ref={chatBoxEl}
          >
            {chatHistory.length === 0 && (
              <Typography variant="body2" textAlign="center" color="text.secondary">
                Starting the chat with {chatNameText}
              </Typography>
            )}
            {chatHistory.map((history, index) => (
              <ChatRow
                client={history.client}
                text={history.text}
                showName={shouldShowName(index)}
                key={index}
              />
            ))}
            {isRecent(adminTypingAt) && <ChatTypingBubbles />}
            {isChatStale() && (
              <Typography variant="body2" textAlign="center" color="text.secondary" p={2}>
                Looks like I'm no longer online. Please email {'support@vitrical.com'} for any
                questions. Thanks!
              </Typography>
            )}
          </Box>
          <Box width="100%" p={1}>
            <form onSubmit={handleSubmit}>
              <Grid container alignItems="center" spacing={1} justifyContent="space-between">
                <Grid item xs>
                  <TextField
                    placeholder={textfieldPlaceholderText}
                    autoComplete="off"
                    fullWidth
                    value={msg}
                    onChange={handleChangeMsg}
                  />
                </Grid>
                <Grid item>
                  <IconButton onClick={handleClickSendChat} disabled={!msg || loading}>
                    <SendIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}

export default ChatOverlay
