import React from 'react'

import GradientBackground from '../components/layouts/GradientBackground'
import useMediaQuery from '@mui/material/useMediaQuery'
import ExamplesSuper from '../components/layouts/ExamplesSuper'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

import actuallyreviewedPreview from '../assets/actuallyreviewed-preview.png'
import flashlabsPreview from '../assets/flashlabs-preview.png'
import chipcustomizerPreview from '../assets/chipcustomizer-preview.png'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'

const StyledImage = styled('img')`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.grey[100]};
`

const projects = [
  {
    title: 'Review Analyzer',
    business: 'Actually Reviewed',
    description:
      'Review analyzer website that uses natural language processing to construct a consensus from product reviews.',
    preview: actuallyreviewedPreview,
    link: 'https://actuallyreviewed.com/',
    color: '#05c655',
  },
  {
    title: 'NFT Marketplace',
    business: 'Flashlabs',
    description:
      'Website that allows the selling, transfer, buying and minting of NFTs. This website features a complete user account system along with integration with Metamask for transferring currency.',
    preview: flashlabsPreview,
    link: 'https://flashlabs.vitrical-demo.com/',
    color: '#fe7000',
  },
  // {
  //   title: 'Poker Chip Customizer',
  //   business: 'Chipcustomizer',
  //   description:
  //     'A photoshop-esque application for designing and printing your own custom poker chips.',
  //   preview: chipcustomizerPreview,
  //   link: 'https://chipcustomizerapp.com/',
  //   color: '#b71c1c',
  // },
]

const Examples = () => {
  const sm = useMediaQuery('(min-width:993px)')

  return (
    <>
      <GradientBackground small={!sm}>
        <ExamplesSuper />
      </GradientBackground>
      <Container>
        <Grid container padding={1} spacing={4} direction="column" alignItems="stretch">
          {projects.map((project, index) => (
            <Grid item key={`project-${index}`}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <StyledImage src={project.preview} alt={project.title} sx={{ mb: 2 }} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h4" gutterBottom display="inline">
                    {project.title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    display="inline"
                    color="text.secondary"
                    noWrap
                  >
                    {' '}
                    for {project.business}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    color="text.secondary"
                    sx={{ mt: 1 }}
                  >
                    {project.description}
                  </Typography>
                  <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={12} sm="auto">
                      <Button
                        variant="contained"
                        href={project.link}
                        target="_blank"
                        fullWidth
                        sx={{
                          backgroundColor: project.color,
                          ':hover': {
                            backgroundColor: project.color,
                          },
                        }}
                        disableElevation
                      >
                        Visit the website
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 4 }} />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  )
}

export default Examples
