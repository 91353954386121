import React from 'react'
import styled from '@mui/system/styled'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import landingWave from '../../assets/gradient-background-wave.svg'

interface LandingWaveProps {
  small: boolean
}

const LandingWave = styled('div', { shouldForwardProp: (prop) => prop !== 'small' })(
  ({ small }: LandingWaveProps) => ({
    minHeight: '800px',
    width: '100%',
    backgroundImage: `url(${landingWave})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: small ? 'cover' : '100% 100%',
    color: '#ffffff',
  })
)

interface InfoBlobProps {
  small: boolean
}

const InfoBlob = ({ small }: InfoBlobProps) => {
  return (
    <LandingWave small={small}>
      <Grid container alignItems="center" justifyContent="space-around" sx={{ pt: 44 }} padding={5}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h4" fontWeight={500} gutterBottom>
            100% Satisfaction from our partners
          </Typography>
          <Typography variant="h6" sx={{ color: '#e6e6e6', mb: 5 }}>
            We work diligently with you to ensure maximum satisfaction with your new website
            application.
          </Typography>
        </Grid>
      </Grid>
    </LandingWave>
  )
}

export default InfoBlob
