import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Paper from '@mui/material/Paper'

interface LayoutCardProps {
  title?: string
  spacing?: number
  children?: JSX.Element | JSX.Element[]
}

const LayoutCard = ({ title = '', spacing = 1, children }: LayoutCardProps) => {
  return (
    <Paper elevation={4} style={{ padding: 5 }}>
      <Grid
        container
        justifyContent="center"
        sx={{
          paddingTop: 2,
          paddingLeft: 4,
          paddingRight: 4,
          paddingBottom: 4,
        }}
      >
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid container direction="column" justifyContent="center" spacing={spacing}>
        {children}
      </Grid>
    </Paper>
  )
}

export default LayoutCard
