import React from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import styled from '@mui/system/styled'

import landingPhoto from '../../assets/landing-photo.png'
import { MEETING_LINK } from '../../util/Api'
import AppearingBox from '../AppearingBox'

const LandingSuperContainer = styled('div')({
  color: '#ffffff',
})

const ImageContainer = styled('div')({
  maxWidth: '370px',
})

const Image = styled('img')({
  width: '100%',
  height: 'auto',
})

const LandingSuper = () => {
  return (
    <LandingSuperContainer>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        padding={2}
        spacing={4}
        sx={{ pt: 6 }}
      >
        <Grid item xs={12} sm="auto" lg={4}>
          <Typography variant="h3" fontWeight={500}>
            App Development Made Easy
          </Typography>
          <Typography variant="h6" fontWeight={500} sx={{ color: '#e6e6e6', mt: 1 }}>
            Get your first prototype live within 10 days
          </Typography>
          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item xs={12} sm="auto">
              <Button
                variant="contained"
                color="info"
                disableElevation
                LinkComponent={Link}
                href={MEETING_LINK}
                fullWidth
              >
                Book a Demo
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <AppearingBox show delay={300}>
            <ImageContainer sx={{ mt: 6 }}>
              <Image src={landingPhoto} />
            </ImageContainer>
          </AppearingBox>
        </Grid>
      </Grid>
    </LandingSuperContainer>
  )
}

export default LandingSuper
