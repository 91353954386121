import React from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

import processPhoto from '../../assets/process-photo.png'

const LandingSuperContainer = styled('div')({
  color: '#ffffff',
})

const ImageContainer = styled('div')({
  maxWidth: '550px',
})

const Image = styled('img')({
  width: '100%',
  height: 'auto',
})

const ProcessSuper = () => {
  const navigate = useNavigate()

  const handleOpenExamples = () => {
    navigate('/examples')
  }

  return (
    <LandingSuperContainer>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        padding={2}
        spacing={4}
        sx={{ pt: 12 }}
      >
        <Grid item>
          <Typography variant="h2" gutterBottom>
            About Us
          </Typography>
          <Typography variant="h5" sx={{ color: '#e6e6e6' }}>
            Agile development
            <br />
            and project management
          </Typography>
          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item>
              <Button variant="contained" color="info" onClick={handleOpenExamples}>
                See examples
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ImageContainer>
            <Image src={processPhoto} alt="block-art" />
          </ImageContainer>
        </Grid>
      </Grid>
    </LandingSuperContainer>
  )
}

export default ProcessSuper
