import Cookies from 'js-cookie'
import { useState, useEffect, useCallback } from 'react'

interface Hook {
  id: number
  property: string
  setState: (value: unknown) => void
}

const state: { [key: string]: any } = {}
let hooks: Hook[] = []
let currentId: number = 0

function subscribe(property: string, setState: (value: unknown) => void): number {
  const newStateJSON = Cookies.get('state')
  if (newStateJSON) {
    const newState = JSON.parse(newStateJSON)
    if (newState) {
      state[property] = newState[property]
      setState(newState[property])
    }
  }
  currentId++
  hooks.push({
    id: currentId,
    property,
    setState,
  })
  return currentId
}

function unsubscribe(id: number) {
  hooks = hooks.filter((hook) => hook.id !== id)
  return hooks
}

function triggerHooks(property: string) {
  hooks.forEach((hook) => {
    if (hook.property !== property) return
    hook.setState(state[property])
  })
}

function setProperty(property: string, value: unknown) {
  state[property] = value
  triggerHooks(property)

  if (state.cookies) {
    Cookies.set('state', JSON.stringify(state), {
      secure: process.env.NODE_ENV === 'development' ? false : true,
    })
  }

  return state[property]
}

export function setStore(property: string, value: unknown): typeof value {
  return setProperty(property, value)
}

export function getStore(property: string) {
  const value = state[property]
  return value
}

export const getIsStoreLoaded = () => {
  return hooks.length !== 0
}

/**
 * Hook to use a store value as a synchronized state.
 */
export default function useStore<T = unknown>(
  property: string
): [T | undefined, (input: T | undefined) => void] {
  const [rstate, setRState] = useState(getStore(property))

  useEffect(() => {
    const index = subscribe(property, setRState)
    return () => {
      unsubscribe(index)
    }
  }, [property])

  const setStoreValue = useCallback(
    (value: unknown) => {
      return setProperty(property, value)
    },
    [property]
  )

  return [rstate, setStoreValue]
}
