import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import DeleteIcon from '@mui/icons-material/Delete'
import TextField from '@mui/material/TextField'
import { TransitionProps } from '@mui/material/transitions'
import SendIcon from '@mui/icons-material/Send'

import { Chat, ChatHistory, deleteChat, getChats, refreshChat, sendChat } from '../util/Api'
import { isRecent } from '../util/Func'
import ChatTypingBubbles from '../components/ChatTypingBubbles'
import { ApiError } from '@vitrical/utils/api'
import useStore from '../util/Store'
import { UserInfo } from '@vitrical/vitrical-auth-api'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Chats = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams({})
  const [token, setToken] = useStore<string>('token')
  const [, setUser] = useStore<UserInfo>('user')

  const [chats, setChats] = useState<Chat[]>([])
  const [now, setNow] = useState(new Date())

  const [msg, setMsg] = useState('')

  const chatBoxEl = useRef<HTMLDivElement>(null)

  const isAtScrollBottom = () => {
    const chatBox = chatBoxEl.current
    if (chatBox) {
      return chatBox.scrollHeight - chatBox.scrollTop === chatBox.clientHeight
    }
    return false
  }

  const scrollBox = (wasAtScrollBottom: boolean) => {
    setTimeout(() => {
      const chatBox = chatBoxEl.current
      if (chatBox) {
        if (wasAtScrollBottom) {
          chatBox.scrollTop = chatBox.scrollHeight
        }
      }
    }, 100)
  }

  const handleLogout = useCallback(() => {
    setUser(undefined)
    setToken(undefined)
    navigate('/login')
  }, [navigate, setToken, setUser])

  const handleChangeMsg = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMsg(e.target.value)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date())
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const getMinutesSince = (int: number) => {
    const lastTyping = new Date(int)
    const diff = now.getTime() - lastTyping.getTime()
    return diff / 1000 / 60
  }

  const getTimeSince = (int: number) => {
    if (int === 0) return 'Never'
    const minutes = getMinutesSince(int)
    if (minutes < 1) return 'Just now'
    if (minutes < 60) return `${Math.round(minutes)} minutes ago`
    const hours = minutes / 60
    if (hours < 24) return `${Math.round(hours)} hours ago`
    const days = hours / 24
    return `${Math.round(days)} days ago`
  }

  const handleClickChat = (chat: Chat) => {
    setSearchParams({ chatId: chat.id })
  }

  const handleClose = () => {
    setSearchParams({ chatId: '' })
  }

  const selectedChat = searchParams.get('chatId')
    ? chats.find((chat) => chat.id === searchParams.get('chatId')) || null
    : null

  const populateChats = useCallback(async () => {
    try {
      if (!token) {
        return handleLogout()
      }
      const res = await getChats({ token })
      setChats(res.chats)
    } catch (err) {
      if (err instanceof ApiError) {
        console.error(err.message)
        console.error(err.stack)
        if (err.status === 403) {
          handleLogout()
        }
      }
    }
  }, [token, handleLogout])

  const typing = !!msg

  useEffect(() => {
    if (!searchParams.get('chatId')) return
    scrollBox(true)
  }, [searchParams])

  const populateRefreshChat = useCallback(async () => {
    if (!selectedChat) return
    if (!token) {
      return handleLogout()
    }
    try {
      const res = await refreshChat({ token, chatId: selectedChat.id, typing })
      const wasAtScrollBottom = isAtScrollBottom()
      setChats((chats) => {
        const index = chats.findIndex((chat) => chat.id === selectedChat.id)
        if (index === -1) return chats
        const newChats = [...chats]
        newChats[index] = {
          ...newChats[index],
          lastClientInteraction: res.lastClientInteraction,
          lastAdminInteraction: res.lastAdminInteraction,
          adminTypingAt: res.adminTypingAt,
          clientTypingAt: res.clientTypingAt,
          history: res.history,
        }
        return newChats
      })
      scrollBox(wasAtScrollBottom)
    } catch (err) {
      if (err instanceof ApiError) {
        console.error(err.message)
        console.error(err.stack)
        if (err.status === 403) {
          handleLogout()
        }
      }
    }
  }, [selectedChat, token, typing, handleLogout])

  useEffect(() => {
    if (!selectedChat) {
      populateChats()
    }
    const interval = setInterval(() => {
      if (selectedChat) {
        populateRefreshChat()
      } else {
        populateChats()
      }
    }, 2000)
    return () => clearInterval(interval)
  }, [populateChats, populateRefreshChat, selectedChat])

  const handleSendChat = async () => {
    if (!selectedChat) return
    if (!token) {
      return handleLogout()
    }
    try {
      const res = await sendChat({ token, chatId: selectedChat.id, text: msg })
      const wasAtScrollBottom = isAtScrollBottom()
      setChats((chats) => {
        const index = chats.findIndex((chat) => chat.id === selectedChat.id)
        if (index === -1) return chats
        const newChats = [...chats]
        newChats[index] = {
          ...newChats[index],
          lastAdminInteraction: res.lastAdminInteraction,
          lastClientInteraction: res.lastClientInteraction,
          adminTypingAt: res.adminTypingAt,
          clientTypingAt: res.clientTypingAt,
          history: res.history,
        }
        return newChats
      })
      setMsg('')
      scrollBox(wasAtScrollBottom)
    } catch (err) {
      if (err instanceof ApiError) {
        console.error(err.message)
        console.error(err.stack)
        if (err.status === 403) {
          handleLogout()
        }
      }
    }
  }

  const handleSubmitChat = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSendChat()
  }

  const isAwaitingResponse = (history: ChatHistory[]) => {
    if (history.length === 0) return false
    const last = history[history.length - 1]
    return last.client
  }

  const handleDeleteChat = async () => {
    if (!selectedChat) return
    if (!token) {
      return handleLogout()
    }
    try {
      await deleteChat({ token, chatId: selectedChat.id })
      setChats((chats) => chats.filter((c) => c.id !== selectedChat.id))
      setSearchParams({ chatId: '' })
    } catch (err) {
      if (err instanceof ApiError) {
        console.error(err.message)
        console.error(err.stack)
        if (err.status === 403) {
          handleLogout()
        }
      }
    }
  }

  const getLastInteraction = (chat: Chat) =>
    chat.lastClientInteraction > chat.lastAdminInteraction
      ? chat.lastClientInteraction
      : chat.lastAdminInteraction

  return (
    <>
      <Box
        sx={(theme) => ({ height: 64, width: '100%', bgcolor: theme.palette.info.main, mb: 4 })}
      />
      <Container>
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          direction="column"
          padding={1}
          spacing={1}
        >
          {chats.length === 0 && (
            <Grid item alignSelf="center">
              <Typography color="text.regular">No chats yet 😀</Typography>
            </Grid>
          )}

          {chats
            .sort((a, b) =>
              getLastInteraction(a) > getLastInteraction(b)
                ? -1
                : getLastInteraction(b) > getLastInteraction(a)
                ? 1
                : 0
            )
            .map((chat) => (
              <Grid item key={chat.id} xs={12} sm={6} md={4}>
                <Box
                  sx={(theme) => ({
                    bgcolor: theme.palette.primary.light,
                    p: 1,
                    borderRadius: 1,
                    transition: 'background-color 0.3s ease',
                    cursor: 'pointer',
                    width: '100%',
                    userSelect: 'none',
                  })}
                  onClick={() => handleClickChat(chat)}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography color="text.regular" variant="subtitle2">
                        {chat.ip}
                      </Typography>
                      <Typography color="text.secondary" variant="caption">
                        {getTimeSince(getLastInteraction(chat))}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        {isRecent(chat.clientTypingAt) ? (
                          <Typography color="text.secondary" variant="caption" sx={{ mr: 1 }}>
                            Typing...
                          </Typography>
                        ) : (
                          isAwaitingResponse(chat.history) && (
                            <Typography color="text.secondary" sx={{ mr: 1 }}>
                              Your turn
                            </Typography>
                          )
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
        </Grid>

        <Dialog
          fullScreen
          open={!!searchParams.get('chatId')}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {selectedChat ? selectedChat.ip : ''}
              </Typography>
              <IconButton color="inherit" onClick={handleDeleteChat}>
                <DeleteIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          {selectedChat && (
            <Box sx={{ height: '100%' }}>
              <Grid
                container
                justifyContent="start"
                direction="column"
                alignItems="stretch"
                padding={1}
                spacing={1}
                sx={{ height: '100%' }}
                flexWrap="nowrap"
              >
                <Grid item flexGrow={1}>
                  <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 180px)' }} ref={chatBoxEl}>
                    {selectedChat.history.map((history, index) => (
                      <Box
                        key={`${history.createdAt}-${index}`}
                        sx={{
                          display: 'flex',
                          justifyContent: !history.client ? 'flex-end' : 'flex-start',
                          alignItems: 'center',
                          padding: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: !history.client ? 'flex-end' : 'flex-start',
                            bgcolor: !history.client ? 'primary.light' : 'info.light',
                            padding: 1,
                            borderRadius: 1,
                            color: !history.client ? 'black' : 'primary.contrastText',
                          }}
                        >
                          <Typography sx={{ wordBreak: 'break-word' }}>{history.text}</Typography>
                          <Typography variant="caption">
                            {getTimeSince(history.createdAt)}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                    {isRecent(selectedChat.clientTypingAt) && <ChatTypingBubbles />}
                  </Box>
                </Grid>
                <Grid item justifySelf="end">
                  <form onSubmit={handleSubmitChat}>
                    <Grid container alignItems="center" spacing={1} flexWrap="nowrap">
                      <Grid item flexGrow={1}>
                        <TextField
                          placeholder={`Reply to ${selectedChat.ip}`}
                          fullWidth
                          autoComplete="off"
                          onChange={handleChangeMsg}
                          value={msg}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton onClick={handleSendChat} disabled={!msg}>
                          <SendIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Box>
          )}
        </Dialog>
      </Container>
    </>
  )
}

export default Chats
