import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Home from './routes/Home'
// import Pricing from './routes/Pricing'
import Process from './routes/Process'
import Playground from './routes/Playground'
import Contact from './routes/Contact'
import toast, { Toaster } from 'react-hot-toast'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import CssBaseline from '@mui/material/CssBaseline'
import ThemeProvider from '@mui/material/styles/ThemeProvider'

import Navbar from './components/layouts/Navbar'
import Footer from './components/layouts/Footer'

import Theme from './Theme'

import Confirm from './routes/Confirm'
import Examples from './routes/Examples'
import GuideLeadForm from './routes/GuideLeadForm'
import ChatOverlay from './components/layouts/ChatOverlay'
import Login from './routes/Login'
import Register from './routes/Register'
import Chats from './routes/Chats'
import PrivacyPolicy from './routes/PrivacyPolicy'
import useStore from './util/Store'
import { useCallback, useEffect } from 'react'
import TokenRefresher from './components/TokenRefresher'
import theme from './Theme'

const App = () => {
  const [cookies, setCookies] = useStore<boolean>('cookies')

  const handleClickAcceptCookies = useCallback(
    (id: string) => {
      toast.dismiss(id)
      setCookies(true)
    },
    [setCookies]
  )

  const populateCookiesToast = useCallback(() => {
    if (typeof window === 'undefined') return
    if (!cookies) {
      toast(
        (t) => (
          <Grid container spacing={2} wrap="nowrap" alignItems="center">
            <Grid item>
              <Typography variant="body2">This website uses cookies</Typography>
            </Grid>
            <Grid item>
              <Button
                size="small"
                color="primary"
                variant="text"
                onClick={() => handleClickAcceptCookies(t.id)}
              >
                Accept
              </Button>
            </Grid>
          </Grid>
        ),
        {
          duration: 999999999,
          id: 'cookies-toast',
          position: 'bottom-center',
          ariaProps: {
            role: 'alert',
            'aria-live': 'assertive',
          },
        }
      )
    } else {
      toast.dismiss('cookies-toast')
    }
  }, [cookies, handleClickAcceptCookies])

  useEffect(() => {
    const timeout = setTimeout(populateCookiesToast, 1500)
    return () => {
      clearTimeout(timeout)
    }
  }, [populateCookiesToast])

  return (
    <ThemeProvider theme={Theme}>
      <Router>
        <CssBaseline />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/playground" element={<Playground />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/process" element={<Process />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/examples" element={<Examples />} />
          <Route path="/guide" element={<GuideLeadForm />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/chats" element={<Chats />} />
          <Route path="/legal" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
        <ChatOverlay />
        <TokenRefresher />
        <Toaster
          gutter={8}
          toastOptions={{
            className: '',
            duration: 5000,
            style: {
              borderRadius: theme.shape.borderRadius,
            },
          }}
        />
      </Router>
    </ThemeProvider>
  )
}

export default App
