import React, { useState, useEffect, useCallback } from 'react'

import GoogleMapReact from 'google-map-react'

import LocationOnIcon from '@mui/icons-material/LocationOn'

import InfoCard from './InfoCard'
import Paper from '@mui/material/Paper'

import { Grid, InputLabel, MenuItem, SelectChangeEvent, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'

import { Select } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import LoadingButton from '@mui/lab/LoadingButton'

import { getGoogleMapInfo } from '../../util/Api'

interface MapCoords {
  lat: number
  lng: number
}

interface MapLocation extends MapCoords {
  color: string
}

const MapLocationIcon = ({ color }: MapLocation) => {
  return <LocationOnIcon style={{ color }} />
}

const SimpleMap = () => {
  const [apiKey, setApiKey] = useState<string | null>(null)
  const [center] = useState<MapCoords>({ lat: 59.95, lng: 30.33 })
  const [zoom] = useState<number>(1)

  const [lat, setLat] = useState<string>('')
  const [lng, setLng] = useState<string>('')
  const [color, setColor] = useState<string>('red')

  const [markers, setMarkers] = useState<MapLocation[]>([])

  const handleChangeLat = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLat(e.target.value)
  }

  const handleChangeLng = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLng(e.target.value)
  }

  const handleAddMarker = () => {
    const newMarker = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
      color,
    }
    setMarkers([...markers, newMarker])
  }

  const handleSetColor = (e: SelectChangeEvent) => {
    setColor(e.target.value)
  }

  const populateGoogleApiKey = useCallback(async () => {
    try {
      const data = await getGoogleMapInfo()
      setApiKey(data.key)
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    populateGoogleApiKey()
  }, [populateGoogleApiKey])

  return (
    <div style={{ width: '100%' }}>
      <InfoCard
        title="Google Map Integration"
        body={
          <span>
            Using Googles Developer API integration we are able to cleanly and effeciently integrate
            features such as Google Maps.
          </span>
        }
      ></InfoCard>
      <br></br>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item>
          <Paper elevation={4} style={{ padding: 8 }}>
            <Grid container>
              <Grid item>
                <Typography variant="h6">Insert Latitude and Longitude</Typography>
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <TextField
                  color={'primary'}
                  fullWidth
                  variant="outlined"
                  label="Latitude"
                  onChange={handleChangeLat}
                  value={lat}
                ></TextField>
              </Grid>
              <Grid item>
                <TextField
                  color={'primary'}
                  fullWidth
                  variant="outlined"
                  label="Longitude"
                  onChange={handleChangeLng}
                  value={lng}
                ></TextField>
              </Grid>
              <Grid item>
                <FormControl sx={{ width: '100%' }} size="small">
                  <InputLabel>Color</InputLabel>
                  <Select
                    color={'primary'}
                    label="Color"
                    autoWidth
                    variant="outlined"
                    onChange={handleSetColor}
                    value={color}
                  >
                    <MenuItem value={'red'}>Red</MenuItem>
                    <MenuItem value={'blue'}>Blue</MenuItem>
                    <MenuItem value={'green'}>Green</MenuItem>
                    <MenuItem value={'purple'}>Purple</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <LoadingButton
                  color={color ? 'primary' : 'secondary'}
                  variant="contained"
                  onClick={handleAddMarker}
                  fullWidth
                >
                  Insert
                </LoadingButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8} justifyContent="center">
          <div style={{ width: '100%', height: '300px' }}>
            {apiKey && (
              <GoogleMapReact
                bootstrapURLKeys={{ key: apiKey }}
                defaultCenter={center}
                defaultZoom={zoom}
              >
                {markers.map((marker) => (
                  <MapLocationIcon color={marker.color} lat={marker.lat} lng={marker.lng} />
                ))}
              </GoogleMapReact>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default SimpleMap
