import React, { useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

import Grid from '@mui/material/Grid'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import InfoCard from './InfoCard'
import Paper from '@mui/material/Paper'

function createData(name: string, color: string, age: number, online: boolean, seen: string) {
  return { name, color, age, online, seen }
}

function getHoursSince(date: string) {
  const now = new Date()
  const hours = Math.round((now.getDate() - new Date(date).getDate()) / 1000 / 60 / 60)
  if (hours === 0) {
    return `Just now`
  }
  if (hours === 1) {
    return `${hours} Hour ago`
  }
  return `${hours} Hours ago`
}

const rows = [
  createData('Elijah Lipsky', 'orange', 20, true, new Date().getDate().toString()),
  createData('John Corolla', 'blue', 46, true, new Date().getDate().toString()),
  createData(
    'Tom Link',
    'yellow',
    23,
    false,
    (new Date().getDate() - 1000 * 60 * 60 * 3).toString()
  ),
  createData('Harry Daniels', 'blue', 45, true, new Date().getDate().toString()),
  createData(
    'Daniel Lipsky',
    'red',
    35,
    false,
    (new Date().getDate() - 1000 * 60 * 60 * 8).toString()
  ),
]

const ExampleDatabase = () => {
  const sm = useMediaQuery('(min-width:600px)')
  const [color, setColor] = useState(true)

  return (
    <div style={{ width: '100%' }}>
      <InfoCard
        title="Databases and Servers"
        body={
          <span>
            Using server redundancy and our automatic deployment strategy, your app's server will be
            consistently monitored 24/7 for vulnerabilities and patches.
            <br />
            <br />
            We take the responsibility of choosing and maintaining a database onto our hands with
            years of experience regarding database management.
          </span>
        }
      >
        <Grid item>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <Typography variant="h6">Color</Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={Boolean(color)}
                onChange={() => setColor(!color)}
                name="color"
                color={color ? 'primary' : 'secondary'}
              />
            </Grid>
          </Grid>
        </Grid>
      </InfoCard>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {sm && <TableCell align="right">Favorite Color</TableCell>}
              <TableCell align="right">Age</TableCell>
              <TableCell align="right">Online</TableCell>
              {sm && <TableCell align="right">Last Seen</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                {sm && <TableCell align="right">{row.color}</TableCell>}
                <TableCell align="right">{row.age}</TableCell>
                <TableCell align="right">
                  {row.online ? (
                    <CheckIcon color={color ? 'primary' : 'secondary'} />
                  ) : (
                    <CloseIcon style={{ color: 'red' }} />
                  )}
                </TableCell>
                {sm && <TableCell align="right">{getHoursSince(row.seen)}</TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ExampleDatabase
