import { IconButton, SvgIcon } from '@mui/material'
import React from 'react'

interface MediaButtonProps {
  link?: string
  svg: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

const MediaButton = ({ link = '', svg, onClick = () => {} }: MediaButtonProps) => {
  return (
    <IconButton href={link} onClick={onClick}>
      <SvgIcon>
        <svg viewBox="0 0 24 24">
          <path fill="currentColor" d={svg} />
        </svg>
      </SvgIcon>
    </IconButton>
  )
}

export default MediaButton
