import React, { useState } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'

import LoadingButton from '@mui/lab/LoadingButton'

import makeStyles from '@mui/styles/makeStyles'
import MembersList from './MembersList'
import Paper from '@mui/material/Paper'
import InfoCard from './InfoCard'
import { Theme } from '@mui/system/createTheme'
import { Member } from './MemberCard'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  gridItem: {
    padding: theme.spacing(2),
  },
  textField: {
    padding: theme.spacing(1),
  },
  containerCard: {
    marginTop: '12px',
    marginBottom: '12px',
  },
  mainCard: {
    backgroundColor: theme.palette.background.main,
    color: theme.palette.background.contrastText,
  },
  subCard: {
    backgroundColor: theme.palette.background.light,
    color: theme.palette.background.dark,
  },
  darkText: {
    color: theme.palette.background.dark,
  },
  spaceAround: {
    margin: '8px',
  },
  switch: {
    display: 'block',
  },
}))

const ExampleUI = () => {
  const classes = useStyles()

  const [elevate, setElevate] = useState(true)
  const [color, setColor] = useState(true)
  const [name, setName] = useState('Tom Williams')
  const [age, setAge] = useState<string>('34')
  const [role, setRole] = useState('Employee')
  const [activeList, setActiveList] = useState<Member[]>([
    {
      name: 'Ryan Williams',
      age: '24',
      role: 'Supervisor',
      admin: false,
    },
    {
      name: 'Bob James',
      age: '22',
      role: 'Janitor',
      admin: false,
    },
  ])
  const [inactiveList, setInactiveList] = useState<Member[]>([
    {
      name: 'Elijah Lipsky',
      age: '19',
      role: 'CEO',
      admin: true,
    },
  ])
  const [nameErrored, setNameErrored] = useState<string | null>(null)
  const [ageErrored, setAgeErrored] = useState<string | null>(null)
  const [roleErrored, setRoleErrored] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const changeMemberAge = (oldList: Member[], member: Member, val: string) => {
    return oldList.map((oldMember) => {
      if (oldMember.name === member.name) {
        oldMember.age = val
      }
      return oldMember
    })
  }

  const changeMemberRole = (oldList: Member[], member: Member, val: string) => {
    return oldList.map((oldMember) => {
      if (oldMember.name === member.name) {
        oldMember.role = val
      }
      return oldMember
    })
  }

  const changeMemberAdmin = (oldList: Member[], member: Member) => {
    return oldList.map((oldMember) => {
      if (oldMember.name === member.name) {
        oldMember.admin = !oldMember.admin
      }
      return oldMember
    })
  }

  const handleChangeAge = (member: Member, val: string, list: 'active' | 'inactive') => {
    if (list === 'active') {
      setActiveList((oldList: Member[]) => changeMemberAge(oldList, member, val))
    } else {
      setInactiveList((oldList: Member[]) => changeMemberAge(oldList, member, val))
    }
  }

  function handleChangeRole(member: Member, val: string, list: 'active' | 'inactive') {
    if (list === 'active') {
      setActiveList((oldList: Member[]) => changeMemberRole(oldList, member, val))
    } else {
      setInactiveList((oldList: Member[]) => changeMemberRole(oldList, member, val))
    }
  }

  function handleChangeAdmin(member: Member, list: 'active' | 'inactive') {
    if (list === 'active') {
      setActiveList((oldList: Member[]) => changeMemberAdmin(oldList, member))
    } else {
      setInactiveList((oldList: Member[]) => changeMemberAdmin(oldList, member))
    }
  }

  const deleteActive = (member: Member) => {
    setActiveList((oldList) => {
      return oldList.filter((oldMember) => oldMember.name !== member.name)
    })
  }
  const deleteInactive = (member: Member) => {
    setInactiveList((oldList) => {
      return oldList.filter((oldMember) => oldMember.name !== member.name)
    })
  }
  const addActive = (member: Member) => {
    setActiveList((oldList) => {
      return [...oldList, member]
    })
  }
  const addInactive = (member: Member) => {
    setInactiveList((oldList) => {
      return [...oldList, member]
    })
  }
  const moveToActive = (member: Member) => {
    deleteInactive(member)
    addActive(member)
  }
  const moveToInactive = (member: Member) => {
    deleteActive(member)
    addInactive(member)
  }
  const sortList = (list: string) => {
    if (list === 'inactive') {
      setInactiveList((oldList) => {
        return [
          ...oldList.sort((a, b) => {
            if (a.admin === true && b.admin === false) {
              return -1
            }
            if (b.admin === true && a.admin === false) {
              return 1
            }
            return 0
          }),
        ]
      })
    } else if (list === 'active') {
      setActiveList((oldList) => {
        return [
          ...oldList.sort((a, b) => {
            if (a.admin === true && b.admin === false) {
              return -1
            }
            if (b.admin === true && a.admin === false) {
              return 1
            }
            return 0
          }),
        ]
      })
    }
  }

  const clickInsert = () => {
    let errored = false
    setLoading(true)
    setNameErrored(null)
    setAgeErrored(null)
    setRoleErrored(null)
    if (!name) {
      setNameErrored('You must include a name')
      errored = true
    }
    if (!age) {
      setAgeErrored('You must include an age')
      errored = true
    }
    if (!role) {
      setRoleErrored('You must include a role')
      errored = true
    }
    if (errored) return setLoading(false)
    if (inactiveList.find((member) => member.name === name)) {
      setNameErrored('Name already exists')
      return setLoading(false)
    }
    if (activeList.find((member) => member.name === name)) {
      setNameErrored('Name already exists')
      return setLoading(false)
    }

    setTimeout(() => {
      setName('')
      setAge('')
      setRole('')
      setLoading(false)
      addInactive({
        name,
        age: age,
        role,
        admin: false,
      })
    }, 500)
  }

  return (
    <div style={{ width: '100%' }}>
      <InfoCard
        title="Application UI"
        body={
          <span>
            We specialize in using sleek, material design standards. With every website or app we
            make, we develop a great intuitive user interfaces.
            <br />
            <br />
            Using Google's Material Design standard, menus are simple and easy to navigate.
          </span>
        }
      >
        <Grid item>
          <Grid container direction="row" alignItems="center" justifyContent="center" padding={2}>
            <Grid item>
              <Typography variant="h6">Elevated Style</Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={Boolean(elevate)}
                onChange={() => setElevate(!elevate)}
                name="elevated"
                color={color ? 'primary' : 'secondary'}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <Typography variant="h6">Color</Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={Boolean(color)}
                onChange={() => setColor(!color)}
                name="color"
                color={color ? 'primary' : 'secondary'}
              />
            </Grid>
          </Grid>
        </Grid>
      </InfoCard>
      <Grid container justifyContent="space-around">
        <Grid item className={classes.gridItem}>
          <Paper elevation={4} style={{ padding: 8 }}>
            <Grid container justifyContent="center">
              <Typography variant="h6">Insert a member</Typography>
            </Grid>
            <Grid container direction="column" justifyContent="center">
              <Grid item className={classes.textField}>
                <TextField
                  disabled={loading}
                  color={color ? 'primary' : 'secondary'}
                  fullWidth
                  variant="outlined"
                  label="Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                  error={Boolean(nameErrored)}
                  helperText={nameErrored || ''}
                />
              </Grid>
              <Grid item className={classes.textField}>
                <TextField
                  disabled={loading}
                  color={color ? 'primary' : 'secondary'}
                  fullWidth
                  variant="outlined"
                  label="Age"
                  value={age}
                  onChange={(e) => {
                    setAge(e.target.value)
                  }}
                  error={Boolean(ageErrored)}
                  helperText={ageErrored || ''}
                />
              </Grid>
              <Grid item className={classes.textField}>
                <TextField
                  disabled={loading}
                  color={color ? 'primary' : 'secondary'}
                  fullWidth
                  variant="outlined"
                  label="Role"
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value)
                  }}
                  error={Boolean(roleErrored)}
                  helperText={roleErrored || ''}
                />
              </Grid>
              <Tooltip arrow title="Click me!" open={true}>
                <LoadingButton
                  color={color ? 'primary' : 'secondary'}
                  variant="contained"
                  onClick={clickInsert}
                  loading={loading}
                >
                  Insert
                </LoadingButton>
              </Tooltip>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Grid container item justifyContent="center">
            <Grid item className={classes.gridItem}>
              <MembersList
                elevate={elevate}
                color={color}
                list={inactiveList}
                onChangeAge={(member, val) => handleChangeAge(member, val, 'inactive')}
                onChangeRole={(member, val) => {
                  handleChangeRole(member, val, 'inactive')
                }}
                onDelete={(member) => {
                  deleteInactive(member)
                }}
                onMove={(member) => {
                  moveToActive(member)
                }}
                onAdmin={(member) => {
                  handleChangeAdmin(member, 'inactive')
                }}
                onSort={() => sortList('inactive')}
              />
            </Grid>
            <Grid item className={classes.gridItem}>
              <MembersList
                elevate={elevate}
                color={color}
                list={activeList}
                active
                onChangeAge={(member, val) => handleChangeAge(member, val, 'active')}
                onChangeRole={(member, val) => {
                  handleChangeRole(member, val, 'active')
                }}
                onDelete={(member) => {
                  deleteActive(member)
                }}
                onMove={(member) => {
                  moveToInactive(member)
                }}
                onAdmin={(member) => {
                  handleChangeAdmin(member, 'active')
                }}
                onSort={() => sortList('active')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default ExampleUI
