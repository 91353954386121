import React from 'react'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import styled from '@mui/system/styled'

import blockArt from '../../assets/block-art.svg'
import { MEETING_LINK } from '../../util/Api'

const LandingSuperContainer = styled('div')({
  color: '#ffffff',
})

const ImageContainer = styled('div')({
  maxWidth: '550px',
})

const Image = styled('img')({
  width: '100%',
  height: 'auto',
})

const PlaygroundSuper = () => {
  return (
    <LandingSuperContainer>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        padding={2}
        spacing={4}
        sx={{ pt: 12 }}
      >
        <Grid item>
          <Typography variant="h2">Playground</Typography>
          <Typography variant="h4" sx={{ color: '#e6e6e6' }}>
            Interactive examples sandbox
          </Typography>
          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item>
              <Button
                variant="contained"
                color="info"
                disableElevation
                LinkComponent={Link}
                href={MEETING_LINK}
              >
                Get a free consultation
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ImageContainer>
            <Image src={blockArt} alt="block-art" />
          </ImageContainer>
        </Grid>
      </Grid>
    </LandingSuperContainer>
  )
}

export default PlaygroundSuper
