import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Collapse from '@mui/material/Collapse'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import GoogleLoginButton from '../components/GoogleLoginButton'
import AuthApi from '../util/AuthApi'
import { useLocation, useNavigate } from 'react-router-dom'
import { ApiError } from '@vitrical/vitrical-auth-api'
import { toast } from 'react-hot-toast'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input'

export type VerifyRegisterDialogProps = {
  open: boolean
  onClose: () => void
}

export const VerifyRegisterDialog = ({ open, onClose }: VerifyRegisterDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Grid container padding={3} justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Verify Your Email</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container padding={3} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">
            We have sent you an email with a link to verify your email address. Please click the
            link in the email to verify your email address.
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  )
}

const Register = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const code = location.search?.split('code=')?.[1]?.split('&')?.[0]
  const userId = location.search?.split('userId=')?.[1]?.split('&')?.[0]

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [authUrl, setAuthUrl] = useState('')
  const [verifyOpen, setVerifyOpen] = useState(false)
  const [agree, setAgree] = useState(false)

  const redirectUri =
    typeof window === 'undefined'
      ? null
      : `${window.location.protocol}//${window.location.host}/login`

  const callbackUrl =
    typeof window === 'undefined'
      ? null
      : `${window.location.protocol}//${window.location.host}/register`

  const populateAuthUrl = useCallback(async () => {
    try {
      if (!redirectUri) return

      const res = await AuthApi.user.google.getAuthUrl({
        redirectUri,
        state: JSON.stringify({ page: '/' }),
      })

      setAuthUrl(res.url)
    } catch (err) {
      console.error(err)
    }
  }, [redirectUri])

  useEffect(() => {
    populateAuthUrl()
  }, [populateAuthUrl])

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleChangeConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value)
  }

  const handleNavLogin = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    navigate('/login')
  }

  const handleRegister = async () => {
    try {
      setLoading(true)
      if (!callbackUrl) return

      if (!email) {
        setError('Email is required')
        return
      }

      if (!password) {
        setError('Password is required')
        return
      }

      if (password.length < 6) {
        setError('Password must be at least 6 characters')
        return
      }

      if (password.length > 200) {
        setError('Password must be less than 200 characters')
        return
      }

      if (password !== confirmPassword) {
        setError('Passwords do not match')
        return
      }

      setError('')

      await AuthApi.user.register.init({ email, password, callbackUrl })

      setVerifyOpen(true)
    } catch (err) {
      console.error(err)
      if (err instanceof ApiError) {
        console.log(err.msg)
        setError(err.msg || err.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleRegister()
  }

  const handleCloseVerify = () => {
    setVerifyOpen(false)
  }

  const verifyUser = useCallback(async () => {
    try {
      if (!code || !userId) return

      await AuthApi.user.register.verify({ code, userId })

      toast.success('Successfully created the new user. Please login to continue')
      navigate('/login')
    } catch (err) {
      console.error(err)
      toast.error('Failed to create the new user')
      if (err instanceof ApiError) {
        setError(err.msg || err.message)
      }
      navigate('/register')
    }
  }, [code, userId, navigate])

  useEffect(() => {
    verifyUser()
  }, [verifyUser])

  return (
    <>
      <VerifyRegisterDialog open={verifyOpen} onClose={handleCloseVerify} />
      <Container>
        <Grid container justifyContent="center" sx={{ mt: 8 }} spacing={4}>
          <Grid item xs={12} sm={8} md={5} lg={4}>
            <FormControl component={'form'} onSubmit={handleSubmit}>
              <Paper elevation={0} variant="outlined">
                <Grid container justifyContent="center" padding={2}>
                  <Grid item>
                    <Typography variant="h5">Create an account</Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" direction="column" padding={2} spacing={2}>
                  <Grid item xs={12}>
                    <Collapse in={Boolean(error)}>
                      <Alert severity="error">{error}</Alert>
                    </Collapse>
                  </Grid>
                  <Grid item>
                    <TextField
                      placeholder="E-mail"
                      type="email"
                      aria-label="signup-email"
                      autoComplete="off"
                      fullWidth
                      onChange={handleChangeEmail}
                      value={email}
                      disabled={loading || !!code}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      placeholder="Password"
                      type="password"
                      aria-label="signup-password"
                      autoComplete="off"
                      fullWidth
                      onChange={handleChangePassword}
                      value={password}
                      disabled={loading || !!code}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      placeholder="Confirm Password"
                      type="password"
                      aria-label="signup-confirm-password"
                      autoComplete="off"
                      fullWidth
                      onChange={handleChangeConfirmPassword}
                      value={confirmPassword}
                      disabled={loading || !!code}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={<Checkbox />}
                      label={
                        <>
                          I agree to the <Link href="/legal">Terms of Service</Link>
                        </>
                      }
                      checked={agree}
                      onChange={() => setAgree(!agree)}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      fullWidth
                      type="submit"
                      disabled={loading || !!code || !agree}
                    >
                      Continue
                    </Button>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" paddingX={2}>
                  <Grid item xs={12}>
                    <GoogleLoginButton
                      href={authUrl}
                      disabled={!redirectUri || !authUrl || loading || !!code}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" padding={2}>
                  <Grid item>
                    <Typography sx={{ mb: 2 }} variant="body2" color="text.secondary">
                      By clicking "Login With Google" you are agreeing to our{' '}
                      <Link href="/legal">Terms of Service</Link>
                    </Typography>
                    <Typography>
                      Already have an account? Login{' '}
                      <Link onClick={handleNavLogin} href="/register">
                        here
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Register
