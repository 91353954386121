import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Collapse from '@mui/material/Collapse'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { Paper } from '@mui/material'
import GoogleLoginButton from '../components/GoogleLoginButton'
import useStore from '../util/Store'
import AuthApi from '../util/AuthApi'
import { ApiError, UserInfo } from '@vitrical/vitrical-auth-api'
import CircularProgress from '@mui/material/CircularProgress'
import { useLocation, useNavigate } from 'react-router-dom'
import { VerifyPhoneDialog } from '../components/VerifyPhoneDialog'

export const attemptJSONParse = (string: string): { [key: string]: any } | null => {
  try {
    return JSON.parse(string)
  } catch (err) {
    return null
  }
}

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [, setUser] = useStore<UserInfo>('user')
  const [, setToken] = useStore<string>('token')
  const [, setTokenExpiresAt] = useStore<number>('tokenExpiresAt')
  const [authUrl, setAuthUrl] = useState('')
  const [verifyPhoneOpen, setVerifyPhoneOpen] = useState(false)

  const [code, setCode] = useState<string | null>(null)
  const [state, setState] = useState<{ [key: string]: any } | null>(null)
  const [page, setPage] = useState<string | null>(null)
  const [redirectUri, setRedirectUri] = useState<string | null>(null)

  useEffect(() => {
    // Set the code from the query param
    const locationSearch = location.search
    const rawCode = locationSearch?.split('code=')?.[1]?.split('&')?.[0]
    const rawState = locationSearch?.split('state=')?.[1]?.split('&')?.[0]
    const rawPage = locationSearch?.split('page=')?.[1]?.split('&')?.[0]

    const code = rawCode ? decodeURIComponent(rawCode) : null
    const state = rawState ? attemptJSONParse(decodeURIComponent(rawState)) : null
    const page = rawPage ? decodeURIComponent(rawPage) : null

    setCode(code)
    setState(state)
    setPage(page)
  }, [location.search])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setRedirectUri(
        `${window.location.protocol}//${window.location.host}${window.location.pathname}`
      )
    }
  }, [])

  const populateAuthUrl = useCallback(async () => {
    try {
      if (!redirectUri) return

      const res = await AuthApi.user.google.getAuthUrl({
        redirectUri,
        state: JSON.stringify({ page: page || '/' }),
      })

      setAuthUrl(res.url)
    } catch (err) {
      console.error(err)
    }
  }, [page, redirectUri])

  useEffect(() => {
    populateAuthUrl()
  }, [populateAuthUrl])

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleNavRegister = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    if (page) {
      navigate(`/register?page=${page}`)
    } else {
      navigate('/register')
    }
  }

  const handleLogin = async () => {
    try {
      const data = await AuthApi.user.login.withEmail({ email, password })
      if ('twoFactorAuth' in data) {
        navigate(`/login/two-factor?email=${email}&id=${data.userId}`)
        return
      }
      setUser(data.user)
      setTokenExpiresAt(data.expiresAt)
      setToken(data.token)

      if (!data.user.phone) {
        setVerifyPhoneOpen(true)
        return
      }

      if (data.user.admin) {
        navigate('/chats')
        return
      }

      if (state?.page) {
        navigate(decodeURIComponent(state.page))
        return
      }

      navigate('/')
    } catch (err) {
      if (err instanceof ApiError) {
        console.error(err.msg)
        setError(err.msg)
      }
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleLogin()
  }

  const handleGoogleLogin = useCallback(async () => {
    try {
      if (!redirectUri) return
      if (!code) return
      setLoading(true)
      const data = await AuthApi.user.google.login({ code, redirectUri })
      setUser(data.user)
      setTokenExpiresAt(data.expiresAt)
      setToken(data.token)

      if (data.user.admin) {
        navigate('/chats')
        return
      }

      if (state?.page) {
        navigate(decodeURIComponent(state.page))
        return
      }

      navigate('/')
    } catch (err) {
      if (err instanceof ApiError) {
        console.error(err.msg)
        setError(err.msg)
      }
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [code, redirectUri, navigate, setToken, setUser, state, setTokenExpiresAt])

  useEffect(() => {
    handleGoogleLogin()
  }, [handleGoogleLogin])

  const handleCloseVerifyPhone = () => {
    setVerifyPhoneOpen(false)
    navigate('/')
  }

  return (
    <>
      <VerifyPhoneDialog open={verifyPhoneOpen} onClose={handleCloseVerifyPhone} />
      {code ? (
        <Grid
          container
          justifyContent="center"
          sx={{ mt: 4 }}
          spacing={4}
          direction="column"
          alignItems="center"
        >
          {!!error && (
            <Grid item xs={12} sm={8} md={5} lg={4}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Container>
          <Grid container justifyContent="center" sx={{ mt: 8 }} spacing={4}>
            <Grid item xs={12} sm={8} md={5} lg={4}>
              <Paper elevation={0} variant="outlined">
                <FormControl component={'form'} sx={{ width: '100%' }} onSubmit={handleSubmit}>
                  <Grid container justifyContent="center" padding={2}>
                    <Grid item>
                      <Typography variant="h5">Login</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    direction="column"
                    padding={2}
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <Collapse in={Boolean(error)}>
                        <Alert severity="error">{error}</Alert>
                      </Collapse>
                    </Grid>
                    <Grid item>
                      <TextField
                        placeholder="E-mail"
                        type="email"
                        aria-label="email"
                        fullWidth
                        onChange={handleChangeEmail}
                        value={email}
                        disabled={loading}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        placeholder="Password"
                        type="password"
                        aria-label="password"
                        fullWidth
                        onChange={handleChangePassword}
                        value={password}
                        disabled={loading}
                      />
                    </Grid>
                    <Grid item>
                      <Button variant="contained" fullWidth type="submit" disabled={loading}>
                        Continue
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center" paddingX={2}>
                    <Grid item xs={12}>
                      <GoogleLoginButton
                        href={authUrl}
                        disabled={!redirectUri || !authUrl || loading}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center" padding={2}>
                    <Grid item>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        By clicking "Login With Google" you are agreeing to our{' '}
                        <Link href="/legal">Terms of Service</Link>
                      </Typography>
                      <Typography>
                        Don&apos;t have an account? Get started{' '}
                        <Link onClick={handleNavRegister} href="/register">
                          here
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </FormControl>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  )
}

export default Login
