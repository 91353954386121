import React, { useState } from 'react'

import GradientBackground from '../components/layouts/GradientBackground'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import { Button, Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material'
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input'
import { submitGuideForm } from '../util/Api'
import Link from '@mui/material/Link'

export const titleText = 'Our free tech startup guide'
export const subtitleText =
  "Working with various web based companies has helped me understand some of the factors that allow software based businesses to succeed. I've put together a guide with the observations I've made, and I hope it helps you and your business thrive online."
export const authorText = '~ Elijah Lipsky'
export const newsletterText = 'I want to recieve promotional emails and updates from Vitrical'
export const termsText = 'I agree to the'
export const submitText = 'Send my guide!'

export const requiredText = 'Required'

const GuideLeadForm = () => {
  const sm = useMediaQuery('(min-width:993px)')

  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [countryCode, setCountryCode] = useState<string | null>(null)
  const [newsletter, setNewsletter] = useState(true)
  const [errors, setErrors] = useState<string[]>([])
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [terms, setTerms] = useState(false)

  const handleChangePhone = (value: string, info: MuiTelInputInfo) => {
    if (value.length === 11) {
      if (!info.countryCode) {
        setCountryCode('US')
        setPhone('+1' + value.substring(1))
        return
      }
    }
    setCountryCode(info.countryCode)
    setPhone(value)
  }

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value)
  }

  const handleChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value)
  }

  const handleToggleNewsletter = () => {
    setNewsletter(!newsletter)
  }

  const handleSubmit = async () => {
    try {
      const newErrors: string[] = []
      if (!email) {
        newErrors.push('email')
      }
      if (!phone || !countryCode) {
        newErrors.push('phone')
      }
      if (!firstName) {
        newErrors.push('firstName')
      }
      if (newErrors.length !== 0) {
        setErrors(newErrors)
        return
      }
      setErrors([])
      setLoading(true)
      await submitGuideForm({
        firstName,
        lastName,
        email,
        phone,
        newsletter,
      })
      setIsSubmitted(true)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const isEverythingFilled = !!(firstName && email && phone && countryCode)

  return (
    <div>
      <GradientBackground small={!sm}>
        <Container>
          <Box sx={{ height: 120 }} />
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={8} md={6}>
              <Typography variant="h4" fontWeight={500} color="white" gutterBottom>
                {titleText}
              </Typography>
              <Typography variant="subtitle1" fontWeight={400} color="#e8e8e8" gutterBottom>
                {subtitleText}
              </Typography>
              <Typography variant="subtitle2" fontWeight={400} color="#e8e8e8" fontStyle="italic">
                {authorText}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center" sx={{ mt: 8 }}>
            <Grid item>
              <Collapse in={!isSubmitted}>
                <Card sx={{ p: 2 }} elevation={24}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            placeholder="First Name"
                            value={firstName}
                            onChange={handleChangeFirstName}
                            aria-label="first name"
                            autoComplete="first name"
                            error={errors.includes('firstName')}
                            helperText={errors.includes('firstName') ? requiredText : undefined}
                            disabled={loading}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            placeholder="Last Name"
                            value={lastName}
                            onChange={handleChangeLastName}
                            aria-label="last name"
                            autoComplete="last name"
                            error={errors.includes('firstName')}
                            disabled={loading}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        placeholder="E-mail"
                        aria-label="email"
                        autoComplete="email"
                        value={email}
                        onChange={handleChangeEmail}
                        error={errors.includes('email')}
                        helperText={errors.includes('email') ? requiredText : undefined}
                        disabled={loading}
                      />
                    </Grid>
                    <Grid item>
                      <MuiTelInput
                        value={phone}
                        onChange={handleChangePhone}
                        fullWidth
                        placeholder="Phone number"
                        autoComplete="phone"
                        error={errors.includes('phone')}
                        helperText={errors.includes('phone') ? requiredText : undefined}
                        disabled={loading}
                      />
                    </Grid>
                    <Grid item xs={12} md>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox size="small" />}
                          label={
                            <>
                              {termsText} <Link href="/legal">Terms of Service</Link>
                            </>
                          }
                          onChange={() => setTerms(!terms)}
                          checked={terms}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md="auto">
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={loading || !terms}
                          >
                            {submitText}
                          </Button>
                        </Grid>
                        <Grid item xs={12} md>
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox size="small" />}
                              label={newsletterText}
                              onChange={handleToggleNewsletter}
                              checked={newsletter}
                              disabled={!isEverythingFilled}
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Collapse>
              <Collapse in={isSubmitted}>
                <Card sx={{ p: 4 }} elevation={24}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography
                        variant="h5"
                        fontWeight={500}
                        color="text.primary"
                        gutterBottom
                        textAlign="center"
                      >
                        Thank you!
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        fontWeight={400}
                        color="text.secondary"
                        textAlign="center"
                      >
                        We have sent you an email with the guide. Please check your spam folder if
                        you don't see it.
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Collapse>
            </Grid>
          </Grid>
        </Container>
      </GradientBackground>
    </div>
  )
}

export default GuideLeadForm
