import styled from '@mui/system/styled'

import landingBackground from '../../assets/gradient-background-5.svg'

interface GradientBackgroundProps {
  small: boolean
}

const GradientBackground = styled('div', { shouldForwardProp: (prop) => prop !== 'small' })(
  ({ small }: GradientBackgroundProps) => ({
    minHeight: small ? '780px' : '860px',
    width: '100%',
    backgroundImage: `url(${landingBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: small ? 'cover' : '100% 100%',
  })
)

export default GradientBackground
