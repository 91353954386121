import React, { useCallback, useEffect } from 'react'
import useStore from '../util/Store'
import { UserInfo } from '@vitrical/vitrical-auth-api'
import { toast } from 'react-hot-toast'
import AuthApi from '../util/AuthApi'
import { useNavigate } from 'react-router-dom'

const TokenRefresher = () => {
  const navigate = useNavigate()
  const [token, setToken] = useStore<string>('token')
  const [, setUser] = useStore<UserInfo>('user')
  const [tokenExpiresAt, setTokenExpiresAt] = useStore<number>('tokenExpiresAt')

  const refreshToken = useCallback(async () => {
    try {
      if (tokenExpiresAt && token) {
        if (Date.now() > tokenExpiresAt - 2 * 60 * 1000) {
          const data = await AuthApi.user.login.withToken({ token })
          setTokenExpiresAt(data.expiresAt)
          setToken(data.token)
          setUser(data.user)
        }
      }
    } catch (err) {
      console.error(err)
      setTokenExpiresAt(undefined)
      setToken(undefined)
      setUser(undefined)
      toast('Logged out due to inactivity')
      navigate('/login')
    }
  }, [tokenExpiresAt, token, setTokenExpiresAt, setUser, navigate, setToken])

  useEffect(() => {
    const interval = setInterval(refreshToken, 5 * 1000)
    return () => {
      clearInterval(interval)
    }
  }, [refreshToken])

  return <></>
}

export default TokenRefresher
