import PropTypes from 'prop-types'
import Paper, { PaperProps } from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  gridItem: {
    padding: theme.spacing(2),
  },
  textField: {
    padding: theme.spacing(1),
  },
  containerCard: {
    marginTop: '12px',
    marginBottom: '12px',
  },
  mainCard: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.background.paper,
  },
  subCard: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.background.paper,
  },
  darkText: {
    color: theme.palette.background.paper,
  },
  spaceAround: {
    margin: '8px',
  },
  switch: {
    display: 'block',
  },
}))

interface InfoCardProps extends PaperProps {
  title: string
  body: JSX.Element
  children?: JSX.Element | JSX.Element[]
}

const InfoCard = ({ title, body, children, ...props }: InfoCardProps) => {
  const classes = useStyles()

  return (
    <Paper elevation={4} sx={{ width: '100%' }} {...props}>
      <Grid container direction="column">
        <Grid item>
          <Grid container direction="row" justifyContent="space-around" alignItems="center">
            <Grid item xs={12} sm={8}>
              <Grid container direction="column">
                <Grid item className={classes.gridItem}>
                  <Typography variant="h4">{title}</Typography>
                </Grid>
                <Grid item className={classes.gridItem}>
                  <Typography variant="body1">{body}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                height={1}
              >
                {children}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

InfoCard.defaultProps = {
  title: 'Example Text',
  body: <span />,
}

InfoCard.propTypes = {
  title: PropTypes.string,
  body: PropTypes.element,
}

export default InfoCard
