import React from 'react'
import styled from '@mui/material/styles/styled'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

const StyledBubble = styled('div')`
  align-items: center;
  display: flex;
  height: 17px;
`

interface StyledDotProps {
  delay: number
}

const StyledDot = styled('div', { shouldForwardProp: (prop) => prop !== 'delay' })<StyledDotProps>`
  animation: mercuryTypingAnimation 1.2s infinite ease-in-out;
  background-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;

  animation-delay: ${(props) => props.delay}ms;

  :last-child {
    margin-right: 0;
  }

  @keyframes mercuryTypingAnimation {
    0% {
      transform: translateY(0px);
      background-color: ${(props) => props.theme.palette.info.light};
    }
    28% {
      transform: translateY(-7px);
      background-color: ${(props) => props.theme.palette.info.main};
    }
    44% {
      transform: translateY(0px);
      background-color: ${(props) => props.theme.palette.info.light};
    }
  }
`

const ChatTypingBubbles = () => {
  return (
    <Grid container justifyContent="start" padding={1}>
      <Grid item>
        <Box
          sx={(theme) => ({
            bgcolor: theme.palette.primary.light,
            color: theme.palette.text.primary,
            p: 1,
            borderRadius: theme.spacing(2),
            paddingLeft: 2,
            paddingRight: 2,
          })}
        >
          <StyledBubble>
            <StyledDot delay={100} />
            <StyledDot delay={200} />
            <StyledDot delay={300} />
          </StyledBubble>
        </Box>
      </Grid>
    </Grid>
  )
}
export default ChatTypingBubbles
