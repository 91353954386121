import React from 'react'

import GradientBackground from '../components/layouts/GradientBackground'
import ProcessSuper from '../components/layouts/ProcessSuper'
import ProcessItem from '../components/layouts/ProcessItem'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

import styled from '@mui/system/styled'

import numberOne from '../assets/number-one.png'
import numberTwo from '../assets/number-two.png'
import numberThree from '../assets/number-three.png'
import numberFour from '../assets/number-four.png'
import numberFive from '../assets/number-five.png'
import numberSix from '../assets/number-six.png'
import eliPhoto from '../assets/eli.jpg'

const BoldText = styled('span')({
  fontWeight: 'bold',
})

const EliImage = styled('img')`
  width: 100%;
  border-radius: 16px;
`

const Process = () => {
  const sm = useMediaQuery('(min-width:993px)')

  return (
    <div>
      <GradientBackground small={!sm}>
        <ProcessSuper />
      </GradientBackground>
      <Container>
        <Grid container sx={{ mb: 12 }}>
          <Grid item>
            <Typography variant="h4" gutterBottom>
              Mission Statement
            </Typography>
            <Typography variant="subtitle1">
              We aim for the highest standards of development. If you aren't satisfied, we aren't
              satisfied. Thats why we won't stop until we get it perfect for you. Customer
              satisfaction is our top priority. We understand that without our amazing clients, we
              woundn't be here!
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              I had been coding since age 13, when I started my first Minecraft server. I
              immediately fostered a love for shaping the digital world, and desired to become as
              proficient as possible in every language I could get my hands on.
              <br />
              <br />
              From programming in video games to developing web software, I've come a pretty long
              way. I've learned a lot about coding since I started, and I'm still enjoying it, even
              8 years later!
            </Typography>
            <Typography variant="subtitle2" sx={{ mt: 4 }}>
              - Elijah Lipsky, Founder
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Process
