import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'

import Brush from '@mui/icons-material/Brush'
import ArrowDownwardRounded from '@mui/icons-material/ArrowDownwardRounded'
import AdjustOutlined from '@mui/icons-material/AdjustOutlined'
import FormatColorText from '@mui/icons-material/FormatColorText'
import FormatColorReset from '@mui/icons-material/FormatColorReset'
import FormatColorFill from '@mui/icons-material/FormatColorFill'

import { ChromePicker, ColorResult } from 'react-color'

import Paper from '@mui/material/Paper'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: 4,
  },
  buttonItem: {
    display: 'flex',
    justifyContent: 'start',
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  arrow: {
    color: '#9A9A9A',
  },
}))

interface ColorPickerCardProps {
  drawColor: string
  fillColor: string
  shadowColor: string
  outlineColor: string
  showDraw: boolean
  showFill: boolean
  showOutline: boolean
  showShadow: boolean
  onDrawChange: (val: string) => void
  onFillChange: (val: string) => void
  onShadowChange: (val: string) => void
  onOutlineChange: (val: string) => void
}

const ColorPickerCard = ({
  drawColor,
  fillColor,
  shadowColor,
  outlineColor,
  onDrawChange,
  onFillChange,
  onShadowChange,
  onOutlineChange,
  showDraw,
  showFill,
  showOutline,
  showShadow,
}: ColorPickerCardProps) => {
  const classes = useStyles()

  const [colorPicker, setColorPicker] = useState<string>('#000000')
  const [activeColorPicker, setActiveColorPicker] = useState('none')

  const handleSetColorPicker = (color: ColorResult) => {
    setColorPicker(color.hex)
  }

  const handleShowPicker = () => {
    if (activeColorPicker === 'draw' && showDraw) return true
    if (activeColorPicker === 'fill' && showFill) return true
    if (activeColorPicker === 'shadow' && showShadow) return true
    if (activeColorPicker === 'outline' && showOutline) return true
    return false
  }

  const handleColorFinish = (val: ColorResult) => {
    if (activeColorPicker === 'draw') {
      onDrawChange(val.hex)
      return
    }
    if (activeColorPicker === 'fill') {
      onFillChange(val.hex)
      return
    }
    if (activeColorPicker === 'shadow') {
      onShadowChange(val.hex)
      return
    }
    if (activeColorPicker === 'outline') {
      onOutlineChange(val.hex)
      return
    }
  }
  useEffect(() => {
    if (!drawColor) return
    setColorPicker(drawColor)
  }, [drawColor])

  useEffect(() => {
    if (!fillColor) return
    setColorPicker(fillColor)
  }, [fillColor])

  useEffect(() => {
    if (!shadowColor) return
    setColorPicker(shadowColor)
  }, [shadowColor])

  useEffect(() => {
    if (!outlineColor) return
    setColorPicker(outlineColor)
  }, [outlineColor])

  useEffect(() => {
    if (activeColorPicker === 'draw') {
      if (!drawColor) return
      setColorPicker(drawColor)
      return
    }
    if (activeColorPicker === 'fill') {
      if (!fillColor) return
      setColorPicker(fillColor)
      return
    }
    if (activeColorPicker === 'shadow') {
      if (!shadowColor) return
      setColorPicker(shadowColor)
      return
    }
    if (activeColorPicker === 'outline') {
      if (!outlineColor) return
      setColorPicker(outlineColor)
      return
    }
  }, [activeColorPicker])

  return (
    <Grid item>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Collapse in={Boolean(showFill || showOutline || showShadow || showDraw)}>
            <div>
              <Paper>
                <div className={classes.buttonGroup}>
                  <div className={classes.buttonItem}>
                    <IconButton onClick={() => setActiveColorPicker('none')}>
                      <FormatColorReset />
                    </IconButton>
                  </div>
                  <div className={classes.buttonItem} style={!showFill ? { display: 'none' } : {}}>
                    <IconButton onClick={() => setActiveColorPicker('fill')}>
                      <FormatColorFill style={{ color: fillColor }} />
                    </IconButton>
                    {activeColorPicker === 'fill' && (
                      <ArrowDownwardRounded className={classes.arrow} />
                    )}
                  </div>
                  <div
                    className={classes.buttonItem}
                    style={!showShadow ? { display: 'none' } : {}}
                  >
                    <IconButton onClick={() => setActiveColorPicker('shadow')}>
                      <FormatColorText style={{ color: shadowColor }} />
                    </IconButton>
                    {activeColorPicker === 'shadow' && (
                      <ArrowDownwardRounded className={classes.arrow} />
                    )}
                  </div>
                  <div
                    className={classes.buttonItem}
                    style={!showOutline ? { display: 'none' } : {}}
                  >
                    <IconButton onClick={() => setActiveColorPicker('outline')}>
                      <AdjustOutlined style={{ color: outlineColor }} />
                    </IconButton>
                    {activeColorPicker === 'outline' && (
                      <ArrowDownwardRounded className={classes.arrow} />
                    )}
                  </div>
                  <div className={classes.buttonItem} style={!showDraw ? { display: 'none' } : {}}>
                    <IconButton onClick={() => setActiveColorPicker('draw')}>
                      <Brush style={{ color: drawColor }} />
                    </IconButton>
                    {activeColorPicker === 'draw' && (
                      <ArrowDownwardRounded className={classes.arrow} />
                    )}
                  </div>
                </div>
              </Paper>
            </div>
          </Collapse>
        </Grid>
        <Grid item>
          <Collapse in={Boolean(handleShowPicker())}>
            <div>
              <ChromePicker
                disableAlpha
                color={colorPicker}
                onChange={handleSetColorPicker}
                onChangeComplete={handleColorFinish}
                styles={{
                  default: {
                    picker: {
                      borderRadius: 8,
                      boxShadow: 'none',
                    },
                    body: {
                      borderRadius: 8,
                    },
                  },
                }}
              />
            </div>
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ColorPickerCard
