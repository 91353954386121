import React from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import { useInView } from 'react-intersection-observer'

import browserExtensionIcon from '../../assets/icons/browser-extension.png'
import ecommerceIcon from '../../assets/icons/ecommerce.png'
import marketingIcon from '../../assets/icons/marketing.png'
import mobileAppIcon from '../../assets/icons/mobile-app.png'
import webAppIcon from '../../assets/icons/web-app.png'
import portfolioWebsiteIcon from '../../assets/icons/portfolio-website.png'
import serverSideApiIcon from '../../assets/icons/server-side-api.png'
import seoIcon from '../../assets/icons/seo.png'

import styled from '@mui/material/styles/styled'
import AppearingBox from '../AppearingBox'

const StyledImg = styled('img')(({ theme }) => ({
  width: 70,
  height: 70,
  objectFit: 'contain',
}))

type ServiceInfo = {
  icon: string
  title: string
  description: string
}

const cards: ServiceInfo[] = [
  {
    icon: browserExtensionIcon,
    title: 'Browser Extensions',
    description:
      'Clean, responsive, and fast browser extension development for Chrome, Firefox, Edge and more.',
  },
  {
    icon: ecommerceIcon,
    title: 'Ecommerce Websites',
    description:
      'Beautiful, responsive, and fast ecommerce websites built with Shopify, WooCommerce, or custom developed according to your needs.',
  },
  {
    icon: marketingIcon,
    title: 'Website Boosting and Promotion',
    description: 'Boost your website traffic and sales with Google ADs, brand bidding, and more.',
  },
  {
    icon: mobileAppIcon,
    title: 'Mobile Apps',
    description: 'Dynamic, responsive, and fast mobile apps built with React Native',
  },
  {
    icon: webAppIcon,
    title: 'Web Apps',
    description: 'Responsive, fast, and dynamic web apps built with React, Vue, or Angular.',
  },
  {
    icon: portfolioWebsiteIcon,
    title: 'Portfolio Websites',
    description:
      'Snappy, attractive, and responsive portfolio websites built with WordPress, React or Vue.',
  },
  {
    icon: serverSideApiIcon,
    title: 'Server Side APIs',
    description:
      'Fast, secure, and scalable server side APIs built with Node.js, Express, and MongoDB or PostgreSQL.',
  },
  {
    icon: seoIcon,
    title: 'SEO Optimization',
    description: 'Optimize your website for search engines and boost your website traffic.',
  },
]

const Services = () => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })

  return (
    <>
      <Box ref={ref}>
        <Container sx={{ mb: 8 }}>
          <AppearingBox sx={{ mb: 6 }} show={inView} delay={300} showOnMobile>
            <Typography variant="h4" fontWeight={500} align="center" gutterBottom>
              Services
            </Typography>
            <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
              Whether it's a browser extension, a mobile app, or a full stack web app, we can help
            </Typography>
          </AppearingBox>
          <Grid container spacing={4} justifyContent="center">
            {cards.map((card, index) => (
              <Grid item key={card.title} xs={12} sm={6} md={4}>
                <AppearingBox
                  show={inView}
                  delay={(index + 1) * 200}
                  sx={{
                    height: '100%',
                  }}
                  showOnMobile
                >
                  <Card
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                    elevation={22}
                  >
                    <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
                      <StyledImg src={card.icon} />
                    </Box>
                    <Box sx={{ p: 3 }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        align="center"
                        sx={{ mb: 2 }}
                      >
                        {card.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" align="center">
                        {card.description}
                      </Typography>
                    </Box>
                  </Card>
                </AppearingBox>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default Services
