import React, { useState } from 'react'

import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input'
import Button from '@mui/material/Button'
import AuthApi from '../util/AuthApi'
import useStore from '../util/Store'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import { TextField } from '@mui/material'
import { ApiError } from '@vitrical/vitrical-auth-api'
import { toast } from 'react-hot-toast'

export type VerifyPhoneDialogProps = {
  open: boolean
  onClose: () => void
}

export const VerifyPhoneDialog = ({ open, onClose }: VerifyPhoneDialogProps) => {
  const [phone, setPhone] = useState('')
  const [loading, setLoading] = useState(false)
  const [token] = useStore<string>('token')
  const [userId, setUserId] = useState('')
  const [code, setCode] = useState('')

  const handleChangePhone = (value: string, info: MuiTelInputInfo) => {
    if (value.length === 11) {
      if (!info.countryCode) {
        setPhone('+1' + value.substring(1))
        return
      }
    }
    setPhone(value)
  }

  const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value)
  }

  const handleRegisterPhone = async () => {
    try {
      if (!token) return
      setLoading(true)
      const { userId } = await AuthApi.user.account.registerPhone({ token, phone })
      setUserId(userId)
    } catch (err) {
      console.error(err)
      if (err instanceof ApiError) {
        toast.error(err.msg || err.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleVerifyPhone = async () => {
    try {
      if (!token) return
      setLoading(true)
      await AuthApi.user.account.verifyPhone({ userId, code })
      onClose()
    } catch (err) {
      console.error(err)
      if (err instanceof ApiError) {
        toast.error(err.msg || err.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Grid container paddingX={3} paddingTop={3} justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Verify Your Phone Number</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container padding={3} spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="body1">
            To enable 2FA and send you alerts about your account and keep you updated about our
            terms of service, we need to verify your phone number.
          </Typography>
        </Grid>
      </Grid>
      <Collapse in={!userId}>
        <Box>
          <Grid container paddingX={3} paddingBottom={3} spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="text.secondary">
                Usage and data rates may apply
              </Typography>
            </Grid>
            <Grid item xs>
              <MuiTelInput
                value={phone}
                onChange={handleChangePhone}
                fullWidth
                placeholder="Phone number"
                aria-label="register-phone"
                autoComplete="phone"
                disabled={loading}
              />
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="contained"
                fullWidth
                disabled={loading}
                onClick={handleRegisterPhone}
              >
                Send Code
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
      <Collapse in={!!userId}>
        <Box>
          <Grid container paddingX={3} paddingBottom={3} spacing={2} alignItems="center">
            <Grid item xs>
              <TextField
                placeholder="Code"
                aria-label="code"
                fullWidth
                onChange={handleChangeCode}
                value={code}
                disabled={loading}
              />
            </Grid>
            <Grid item xs="auto">
              <Button variant="contained" fullWidth disabled={loading} onClick={handleVerifyPhone}>
                Verify Code
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Dialog>
  )
}
