import React, { useState } from 'react'

import Button from '@mui/material/Button'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'
import ArrowRight from '@mui/icons-material/ArrowRight'

import withStyles from '@mui/styles/withStyles'
import makeStyles from '@mui/styles/makeStyles'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={3}
    anchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const useStyles = makeStyles((theme) => ({
  icon: {
    transform: 'rotate(0.75turn)',
  },
  iconOpen: {
    transform: 'rotate(0.25turn)',
  },
}))

//Types?
interface DropdownButtonProps {
  title: string
  items: string[]
  onSelect: (val: string) => void
}

const DropdownButton = ({ title, items, onSelect }: DropdownButtonProps) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement | null>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button variant="contained" onClick={handleClick} fullWidth>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Grid item flexGrow={1}>
            <div style={{ width: '100px' }}>
              <Typography noWrap>{title}</Typography>
            </div>
          </Grid>
          <Grid item>
            <ArrowRight
              className={Boolean(anchorEl) ? classes.iconOpen : classes.icon}
              fontSize="small"
            />
          </Grid>
        </Grid>
      </Button>
      <StyledMenu
        style={{ maxWidth: 200 }}
        id={title + '-menu-element'}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div style={{ maxHeight: 240, overflow: 'auto' }}>
          {items.map((item: string, index: number) => {
            return (
              <Tooltip disableInteractive key={index + '-' + title} title={item}>
                <MenuItem
                  onClick={() => {
                    onSelect(item)
                    handleClose()
                  }}
                >
                  <Typography noWrap>{item}</Typography>
                </MenuItem>
              </Tooltip>
            )
          })}
        </div>
      </StyledMenu>
    </div>
  )
}

export default DropdownButton
