import React from 'react'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'

import styled from '@mui/system/styled'

import appPhoto from '../../assets/app-photo.png'

const DescriptionImage = styled('img')({
  width: '100%',
  height: 'auto',
})

const DescriptionText = () => {
  return (
    <Container>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" sx={{ mb: 3 }}>
            Slick, powerful, perfectly crafted applications
          </Typography>
          <Typography variant="subtitle1">
            Using the latest technologies, we take the complex development decisions out of your
            hands to let you focus on the big picture. You’ll never have to worry about switching to
            the latest and greatest tech out there. We have you covered.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DescriptionImage src={appPhoto} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default DescriptionText
