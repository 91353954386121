import React from 'react'

import GradientBackground from '../components/layouts/GradientBackground'
import LandingSuper from '../components/layouts/LandingSuper'
import Column from '../components/layouts/Column'
import InfoBlob from '../components/layouts/InfoBlob'
import IntroText from '../components/layouts/IntroText'
import DescriptionText from '../components/layouts/DescriptionText'
import CloudText from '../components/layouts/CloudText'
import useMediaQuery from '@mui/material/useMediaQuery'
//import TwitterFeed from '../components/examples/TwitterFeed'
import QuoteText from '../components/layouts/QuoteText'
import Services from '../components/layouts/Services'

const Home = () => {
  const sm = useMediaQuery('(min-width:993px)')

  return (
    <div>
      <GradientBackground small={!sm}>
        <LandingSuper />
      </GradientBackground>
      <Column>
        <Services />
      </Column>
      <Column>
        <IntroText />
      </Column>
      <Column>
        <DescriptionText />
      </Column>
      <InfoBlob small={!sm} />
      <Column>
        <CloudText />
      </Column>
      {/* <Column>
        <TwitterFeed />
      </Column> */}
      <Column>
        <QuoteText />
      </Column>
      {/* <Column>
        <LinkedinFeed />
      </Column> 
      <Column>
        <PricingTextLink />
      </Column> */}
    </div>
  )
}

export default Home
