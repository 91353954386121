import React from 'react'

import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Zoom from '@mui/material/Zoom'

import ArrowUpwardSharp from '@mui/icons-material/ArrowUpwardSharp'
import ArrowDownwardSharp from '@mui/icons-material/ArrowDownwardSharp'
import Receipt from '@mui/icons-material/Receipt'
import Delete from '@mui/icons-material/Delete'

import makeStyles from '@mui/styles/makeStyles'

import Paper from '@mui/material/Paper'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
  },
  divider: {
    margin: theme.spacing(1, 0.5),
  },
}))

interface TextButtonsProps {
  onMoveUp: () => void
  onMoveDown: () => void
  onDuplicate: () => void
  onDelete: () => void
  show: boolean
}

const TextButtons = ({ onMoveUp, onMoveDown, onDuplicate, onDelete, show }: TextButtonsProps) => {
  const classes = useStyles()
  return (
    <Zoom in={Boolean(show)}>
      <div>
        <Paper elevation={4} className={classes.paper}>
          <IconButton onClick={onMoveUp} aria-label="move up">
            <ArrowUpwardSharp />
          </IconButton>
          <IconButton onClick={onMoveDown} aria-label="move down">
            <ArrowDownwardSharp />
          </IconButton>
          <IconButton onClick={onDuplicate} aria-label="duplicate">
            <Receipt />
          </IconButton>
          <Divider flexItem orientation="vertical" className={classes.divider} />
          <IconButton onClick={onDelete} aria-label="delete">
            <Delete />
          </IconButton>
        </Paper>
      </div>
    </Zoom>
  )
}

export default TextButtons
