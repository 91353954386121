import React, { useState, useEffect, useCallback } from 'react'

import GradientBackground from '../components/layouts/GradientBackground'
import ContactSuper from '../components/layouts/ContactSuper'
import useMediaQuery from '@mui/material/useMediaQuery'

import { getCompanyInfo } from '../util/Api'
import useIsMounted from 'react-is-mounted-hook'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

const Contact = () => {
  const sm = useMediaQuery('(min-width:993px)')
  const isMounted = useIsMounted()
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const formatPhone = useCallback(() => {
    return `+1 (${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}`
  }, [phone])

  const formatTel = useCallback(() => {
    return `${phone.substr(0, 3)}-${phone.substr(3, 3)}-${phone.substr(6, 4)}`
  }, [phone])

  const populateCEOInfo = useCallback(async () => {
    try {
      const data = await getCompanyInfo()
      if (!isMounted()) return
      setEmail(data.email)
      setPhone(data.phone)
    } catch (err) {
      console.error(err)
    }
  }, [isMounted])

  useEffect(() => {
    populateCEOInfo()
  }, [populateCEOInfo])

  return (
    <div>
      <GradientBackground small={!sm}>
        <ContactSuper />
      </GradientBackground>
      <Grid container justifyContent="center" spacing={8} direction="column" alignItems="center">
        <Grid item>
          <Typography variant="h3" textAlign="center" gutterBottom>
            E-mail
          </Typography>
          <Link
            variant="h5"
            textAlign="center"
            sx={{ textDecoration: 'none' }}
            color="text.primary"
            href={`mailto: ${email}`}
          >
            {email}
          </Link>
        </Grid>
        <Grid item>
          <Typography variant="h3" textAlign="center" gutterBottom>
            Phone
          </Typography>
          <Link
            variant="h5"
            textAlign="center"
            sx={{ textDecoration: 'none' }}
            color="text.primary"
            href={`tel:${formatTel()}`}
          >
            {formatPhone()}
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}

export default Contact
