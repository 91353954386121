import React, { useState, useRef, useEffect } from 'react'

import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Slider from '@mui/material/Slider'
import Collapse from '@mui/material/Collapse'
import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'

import InfoCard from './InfoCard'
import LayoutCard from './LayoutCard'
import DropdownButton from './DropdownButton'
import TextButtons from './TextButtons'
import ElementButtons from './ElementButtons'
import ColorPickerCard from './ColorPickerCard'

import { fabric } from 'fabric'

const ExampleFabric = () => {
  const [elevate, setElevate] = useState(true)
  const [color, setColor] = useState(true)
  const [fillColor, setFillColor] = useState('#185CC3')
  const [outlineColor, setOutlineColor] = useState('#000000')
  const [shadowColor, setShadowColor] = useState('#000000')
  const [shadowThickness, setShadowThickness] = useState(0)
  const [outlineThickness, setOutlineThickness] = useState(0)
  const [fontFamily, setFontFamily] = useState('Calibri')
  const [formats, setFormats] = useState([])
  const [textAlign, setTextAlign] = useState('justify-left')
  const [fontSize, setFontSize] = useState(72)
  const [drawMode, setDrawMode] = useState(false)
  const [drawColor, setDrawColor] = useState('#9813D1')
  const [drawThickness, setDrawThickness] = useState(10)

  const [selectionType, setSelectionType] = useState('none')

  const fontList = ['Calibri', 'Arial', 'Times New Roman', 'Impact', 'Verdana', 'Courier New']

  const ctx = useRef(null)
  const canvasRef = useRef()
  const canvasContainerRef = useRef()
  const canvasItemRef = useRef()

  const handleMoveUp = () => {
    if (!ctx.current) return
    const active = ctx.current.getActiveObject()
    if (active) {
      active.bringForward()
      ctx.current.renderAll()
    }
  }

  const handleMoveDown = () => {
    if (!ctx.current) return
    const active = ctx.current.getActiveObject()
    if (active) {
      active.sendBackwards()
      ctx.current.renderAll()
    }
  }

  const handleDelete = () => {
    if (!ctx.current) return
    const selected = ctx.current?.getActiveObjects()
    if (!selected) return

    ctx.current.discardActiveObject()
    ctx.current.remove(...selected)
  }

  const handleDuplicate = () => {
    if (!ctx.current) return
    const objects = ctx.current.getActiveObjects()
    if (objects && objects.length === 1) {
      const obj = ctx.current.getActiveObject()
      obj.clone((cloned) => {
        cloned.set({ left: cloned.left + 20, top: cloned.top + 20 })
        ctx.current.add(cloned)
      })
    } else if (objects) {
      objects.forEach((obj) => {
        obj.clone((cloned) => {
          cloned.set({ left: cloned.left + 325, top: cloned.top + 325 })
          ctx.current.add(cloned)
        })
      })
    }
    ctx.current.renderAll()
  }

  const onSelectElement = (e) => {
    if (!e.selected || e.selected.length === 0) {
      setSelectionType('none')
      return
    }
    if (e.selected.length > 1) {
      setSelectionType('group')
      return
    }
    const active = e.selected[0]
    const type = active.get('type')

    setFillColor(active.fill)
    setSelectionType(type)

    if (type === 'textbox') {
      setShadowThickness(active.shadow.offsetX * 10)
      setOutlineThickness(active.strokeWidth * 10)
      setFontFamily(active.fontFamily)
      const textFormats = []
      if (active.fontWeight === 'bold') textFormats.push('bold')
      if (active.fontStyle === 'italic') textFormats.push('italic')
      if (active.underline) textFormats.push('underline')
      if (active.overline) textFormats.push('overline')
      if (active.linethrough) textFormats.push('linethrough')
      setFormats(textFormats)
      setTextAlign(active.textAlign)
      setFontSize(active.fontSize)
      setShadowColor(active.shadow.color)
      setOutlineColor(active.stroke)
    }
  }

  const changeSelectedElement = () => {
    const selected = ctx.current.getActiveObjects()

    if (selected.length === 0) return
    if (selected.length > 1) return

    const active = selected[0]
    const type = active.get('type')

    active.set('fill', fillColor)

    if (type === 'textbox') {
      active.set(
        'shadow',
        `${shadowColor} ${Math.floor(shadowThickness / 10)} ${Math.floor(
          shadowThickness / 10
        )} ${Math.floor(shadowThickness / 10)}`
      )
      active.set('stroke', outlineColor)
      active.set('strokeWidth', Math.floor(outlineThickness / 10))
      active.set('fontFamily', fontFamily)
      active.set('fontSize', fontSize)
      active.set('textAlign', textAlign)
      if (formats.includes('bold')) active.set('fontWeight', 'bold')
      else active.set('fontWeight', 'normal')
      if (formats.includes('italic')) active.set('fontStyle', 'italic')
      else active.set('fontStyle', 'normal')
      if (formats.includes('underline')) active.set('underline', true)
      else active.set('underline', false)
      if (formats.includes('overline')) active.set('overline', true)
      else active.set('overline', false)
      if (formats.includes('linethrough')) active.set('linethrough', true)
      else active.set('linethrough', false)
    }
    ctx.current.renderAll()
  }

  const addRect = () => {
    const canvasContainer = canvasContainerRef.current

    const idealX = canvasContainer.clientWidth / 2
    const idealY = canvasContainer.clientHeight / 2
    ctx.current.add(
      new fabric.Rect({
        originX: 'center',
        originY: 'center',
        left: idealX,
        top: idealY,
        fill: fillColor,
        width: 100,
        height: 100,
        angle: 0,
      })
    )
  }

  const addCircle = () => {
    const canvasContainer = canvasContainerRef.current

    const idealX = canvasContainer.clientWidth / 2
    const idealY = canvasContainer.clientHeight / 2
    ctx.current.add(
      new fabric.Circle({
        originX: 'center',
        originY: 'center',
        left: idealX,
        top: idealY,
        fill: fillColor,
        radius: 80,
      })
    )
  }

  const addTextbox = () => {
    const canvasContainer = canvasContainerRef.current

    const idealX = canvasContainer.clientWidth / 2
    const idealY = canvasContainer.clientHeight / 2
    ctx.current.add(
      new fabric.Textbox('Textbox', {
        textAlign: textAlign,
        originX: 'center',
        originY: 'center',
        left: idealX,
        top: idealY,
        width: 100,
        height: 100,
        fontFamily: fontFamily,
        fontSize: fontSize,
        linethrough: formats.includes('linethrough'),
        overline: formats.includes('overline'),
        underline: formats.includes('underline'),
        fontStyle: formats.includes('italic') ? 'italic' : 'normal',
        fontWeight: formats.includes('bold') ? 'bold' : 'normal',
        shadow: `${shadowColor} ${Math.floor(shadowThickness / 10)} ${Math.floor(
          shadowThickness / 10
        )} ${Math.floor(shadowThickness / 10)}`,
        stroke: outlineColor,
        strokeWidth: Math.floor(outlineThickness / 10),
        fill: fillColor,
      })
    )
  }

  const addImage = () => {
    const canvasContainer = canvasContainerRef.current

    const idealX = canvasContainer.clientWidth / 2
    const idealY = canvasContainer.clientHeight / 2

    new fabric.Image.fromURL(
      'https://upload.wikimedia.org/wikipedia/commons/8/8a/Banana-Single.jpg',
      function (oImg) {
        const ratio = 0.04
        oImg.set({
          originX: 'center',
          originY: 'center',
          left: idealX,
          top: idealY,
          scaleX: ratio,
          scaleY: ratio,
        })
        ctx.current.add(oImg)
        ctx.current.renderAll()
      },
      { crossOrigin: 'Anonymous' }
    )
  }

  const toggleBrush = () => {
    setDrawMode((mode) => !mode)
  }

  const downloadCanvas = () => {
    const uri = ctx.current.toDataURL('image/png')
    let link = document.createElement('a')
    link.download = 'Your Amazing Canvas'
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const clearCanvas = () => {
    ctx.current.discardActiveObject()
    ctx.current.remove(...ctx.current.getObjects())
  }

  const addStartupObjects = () => {
    const canvasContainer = canvasContainerRef.current

    const idealX = canvasContainer.clientWidth / 2
    const idealY = canvasContainer.clientHeight / 2

    ctx.current.add(
      new fabric.Rect({
        originX: 'center',
        originY: 'center',
        left: idealX,
        top: idealY,
        fill: '#00ff1a',
        width: 200,
        height: 100,
        angle: 30,
      })
    )

    ctx.current.add(
      new fabric.Circle({
        originX: 'center',
        originY: 'center',
        left: idealX,
        top: idealY,
        fill: '#9813d1',
        radius: 80,
      })
    )

    ctx.current.add(
      new fabric.Textbox('Textbox', {
        textAlign: textAlign,
        originX: 'center',
        originY: 'center',
        left: idealX,
        top: idealY,
        width: 100,
        height: 100,
        fontFamily: fontFamily,
        fontSize: fontSize,
        linethrough: formats.includes('linethrough'),
        overline: formats.includes('overline'),
        underline: formats.includes('underline'),
        fontStyle: formats.includes('italic') ? 'italic' : 'normal',
        fontWeight: formats.includes('bold') ? 'bold' : 'normal',
        shadow: `${shadowColor} ${Math.floor(shadowThickness / 10)} ${Math.floor(
          shadowThickness / 10
        )} ${Math.floor(shadowThickness / 10)}`,
        stroke: outlineColor,
        strokeWidth: Math.floor(outlineThickness / 10),
        fill: fillColor,
      })
    )

    ctx.current.renderAll()
  }

  useEffect(() => {
    if (ctx.current) return
    ctx.current = new fabric.Canvas('fabricCanvas', {
      backgroundColor: '#e7e7e7',
      selectionLineWidth: 2,
      preserveObjectStacking: true,
    })

    const canvasContainer = canvasContainerRef.current
    const canvasItem = canvasItemRef.current

    canvasItem.style.width = `${canvasContainer.clientWidth}px`
    canvasItem.style.height = `${canvasContainer.clientHeight}px`
    ctx.current.setDimensions({
      width: canvasItem.clientWidth,
      height: canvasItem.clientHeight,
    })

    const listener = window.addEventListener('resize', (e) => {
      if (!canvasContainer) return
      if (!ctx.current) return
      if (!canvasItem) return
      canvasItem.style.width = `${canvasContainer.clientWidth}px`
      canvasItem.style.height = `${canvasContainer.clientHeight}px`
      ctx.current.setDimensions({
        width: canvasItem.clientWidth,
        height: canvasItem.clientHeight,
      })
    })

    ctx.current.on('selection:created', (e) => onSelectElement(e))
    ctx.current.on('selection:updated', (e) => onSelectElement(e))
    ctx.current.on('selection:cleared', (e) => onSelectElement(e))

    addStartupObjects()

    return () => {
      window.removeEventListener('resize', listener)
      ctx.current.clear()
      ctx.current = null
    }
  }, [])

  useEffect(() => {
    if (!ctx.current) return
    changeSelectedElement()
  }, [
    ctx,
    fillColor,
    shadowThickness,
    outlineThickness,
    fontFamily,
    formats,
    textAlign,
    fontSize,
    outlineColor,
    shadowColor,
  ])

  useEffect(() => {
    if (!ctx.current) return
    ctx.current.discardActiveObject()
    ctx.current.renderAll()
    if (drawMode) {
      ctx.current.isDrawingMode = true
      ctx.current.freeDrawingBrush.color = drawColor
      ctx.current.freeDrawingBrush.width = drawThickness
      return
    }
    ctx.current.isDrawingMode = false
  }, [drawMode])

  useEffect(() => {
    if (!ctx.current) return
    ctx.current.freeDrawingBrush.color = drawColor
    ctx.current.freeDrawingBrush.width = drawThickness + 1
    ctx.current.freeDrawingBrush.shadow = `${shadowColor} ${Math.floor(
      shadowThickness / 10
    )} ${Math.floor(shadowThickness / 10)} ${Math.floor(shadowThickness / 10)}`
  }, [drawColor, drawThickness, outlineThickness, shadowThickness, outlineColor, shadowColor, ctx])

  return (
    <div style={{ width: '100%', minHeight: 900 }}>
      <InfoCard
        title="Interactive Canvas"
        body={
          <span>
            We can create a canvas interface for users to modify and interact with.
            <br />
            <br />
            When the canvas is complete, the possiblities of what you can do with it are endless!
          </span>
        }
      >
        <Grid item>
          <Grid container direction="row" alignItems="center" justifyContent="center">
            <Grid item>
              <Typography variant="h6">Elevated Style</Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={elevate}
                onChange={() => setElevate(!elevate)}
                name="elevated"
                color={color ? 'primary' : 'secondary'}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <Typography variant="h6">Color</Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={color}
                onChange={() => setColor(!color)}
                name="elevated"
                color={color ? 'primary' : 'secondary'}
              />
            </Grid>
          </Grid>
        </Grid>
      </InfoCard>
      <Grid container justifyContent="space-around" spacing={1} style={{ marginTop: '12px' }}>
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <LayoutCard title="Insert an element">
                <Grid item>
                  <Button
                    disableElevation={!elevate}
                    fullWidth
                    variant="contained"
                    onClick={addRect}
                    color={color ? 'primary' : 'secondary'}
                  >
                    Square
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disableElevation={!elevate}
                    fullWidth
                    variant="contained"
                    onClick={addCircle}
                    color={color ? 'primary' : 'secondary'}
                  >
                    Circle
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disableElevation={!elevate}
                    fullWidth
                    variant="contained"
                    onClick={addTextbox}
                    color={color ? 'primary' : 'secondary'}
                  >
                    Textbox
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disableElevation={!elevate}
                    fullWidth
                    variant="contained"
                    onClick={addImage}
                    color={color ? 'primary' : 'secondary'}
                  >
                    Image
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disableElevation={!elevate}
                    fullWidth
                    variant="contained"
                    onClick={toggleBrush}
                    color={color ? 'primary' : 'secondary'}
                  >
                    {drawMode ? (
                      <Check style={{ marginRight: 3 }} />
                    ) : (
                      <Close style={{ marginRight: 3 }} />
                    )}
                    Brush
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disableElevation={!elevate}
                    fullWidth
                    variant="contained"
                    onClick={downloadCanvas}
                    color={color ? 'primary' : 'secondary'}
                  >
                    Download Canvas
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disableElevation={!elevate}
                    fullWidth
                    variant="contained"
                    onClick={clearCanvas}
                    color={color ? 'primary' : 'secondary'}
                  >
                    Clear Canvas
                  </Button>
                </Grid>
              </LayoutCard>
            </Grid>
            <Grid item>
              <Collapse in={selectionType === 'textbox' || drawMode}>
                <LayoutCard title="Settings">
                  <Grid item>
                    <Grid direction="row" container justifyContent="space-between" spacing={1}>
                      <Grid item>
                        <Typography variant="caption">Shadow</Typography>
                      </Grid>
                      <Grid item xs>
                        <Slider
                          color={color ? 'primary' : 'secondary'}
                          value={shadowThickness}
                          onChange={(e, val) => {
                            setShadowThickness(val)
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Collapse in={!drawMode}>
                      <Grid direction="row" container justifyContent="space-between" spacing={1}>
                        <Grid item>
                          <Typography variant="caption">Outline</Typography>
                        </Grid>
                        <Grid item xs>
                          <Slider
                            color={color ? 'primary' : 'secondary'}
                            value={outlineThickness}
                            onChange={(e, val) => {
                              setOutlineThickness(val)
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                  <Grid item>
                    <Collapse in={!drawMode}>
                      <DropdownButton
                        color={color ? 'primary' : 'secondary'}
                        title={fontFamily}
                        items={fontList}
                        onSelect={(s) => {
                          setFontFamily(s)
                        }}
                      />
                    </Collapse>
                  </Grid>
                  <Grid item>
                    <Collapse in={drawMode}>
                      <Grid direction="row" container justifyContent="space-between" spacing={1}>
                        <Grid item>
                          <Typography variant="caption">Brush Size</Typography>
                        </Grid>
                        <Grid item xs>
                          <Slider
                            color={color ? 'primary' : 'secondary'}
                            value={drawThickness}
                            onChange={(e, val) => {
                              setDrawThickness(val)
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                </LayoutCard>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
        <Grid item flexGrow={1}>
          <Grid
            container
            justifyContent="stretch"
            direction="column"
            alignContent="stretch"
            spacing={2}
          >
            <Grid item flexGrow={1}>
              <div
                ref={canvasContainerRef}
                style={{
                  width: '100%',
                  height: '300px',
                }}
              >
                <div
                  ref={canvasItemRef}
                  style={{
                    position: 'absolute',
                  }}
                >
                  <canvas
                    id="fabricCanvas"
                    ref={canvasRef}
                    style={{
                      borderRadius: 3,
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                  <ElementButtons
                    show={Boolean(selectionType !== 'none' && selectionType !== 'group')}
                    onMoveUp={() => {
                      handleMoveUp()
                    }}
                    onMoveDown={() => {
                      handleMoveDown()
                    }}
                    onDuplicate={() => {
                      handleDuplicate()
                    }}
                    onDelete={() => {
                      handleDelete()
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextButtons
                    show={Boolean(selectionType === 'textbox')}
                    alignment={textAlign}
                    formats={formats}
                    onChange={(e) => {
                      setTextAlign(e.alignment)
                      setFormats(e.formats)
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ColorPickerCard
            showDraw={selectionType === 'none' && drawMode}
            drawColor={drawColor}
            onDrawChange={(value) => {
              if (!value) return
              setDrawColor(value)
            }}
            showFill={selectionType !== 'none' && selectionType !== 'group'}
            fillColor={fillColor}
            onFillChange={(value) => {
              if (!value) return
              setFillColor(value)
            }}
            showOutline={selectionType === 'textbox'}
            outlineColor={outlineColor}
            onOutlineChange={(value) => {
              if (!value) return
              setOutlineColor(value)
            }}
            showShadow={selectionType === 'textbox' || drawMode}
            shadowColor={shadowColor}
            onShadowChange={(value) => {
              if (!value) return
              setShadowColor(value)
            }}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default ExampleFabric
