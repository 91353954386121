import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'
import Grow from '@mui/material/Grow'
import Star from '@mui/icons-material/Star'
import Delete from '@mui/icons-material/Delete'
import ArrowUpward from '@mui/icons-material/ArrowUpward'
import ArrowLeft from '@mui/icons-material/ArrowLeft'
import ArrowRight from '@mui/icons-material/ArrowRight'
import ArrowDownward from '@mui/icons-material/ArrowDownward'

import styled from '@mui/material/styles/styled'

const SmallAvatar = styled(Avatar)`
  width: ${(props) => props.theme.spacing(4)};
  height: ${(props) => props.theme.spacing(4)};
`

export interface Member {
  admin: boolean
  name: string
  age: string
  role: string
}

interface MemberCardProps {
  member: Member
  active?: boolean
  onChangeAge?: (newValue: string) => void
  onChangeRole?: (newValue: string) => void
  onDelete?: () => void
  onAdmin?: () => void
  onMove?: () => void
  elevate?: boolean
  color?: boolean
}

const MemberCard = ({
  member,
  active = false,
  onChangeAge = () => {},
  onChangeRole = () => {},
  onDelete = () => {},
  onAdmin = () => {},
  onMove = () => {},
  elevate = false,
  color = false,
}: MemberCardProps) => {
  const [enterAnimation, setEnterAnimation] = useState<boolean>(false)
  const [age, setAge] = useState<string>(member.age)
  const [role, setRole] = useState<string>(member.role)
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))

  const chooseIcon = () => {
    if (active) {
      if (sm) return <ArrowUpward />
      return <ArrowLeft />
    }
    if (sm) return <ArrowDownward />
    return <ArrowRight />
  }

  const getColor = () => {
    if (color) {
      if (member.admin) return 'primary'
      return 'default'
    }
    if (member.admin) return 'secondary'
    return 'default'
  }

  useEffect(() => {
    setEnterAnimation(true)
  }, [setEnterAnimation])

  return (
    <Grow in={enterAnimation}>
      <Card variant={elevate ? 'elevation' : 'outlined'} elevation={elevate ? 4 : 0}>
        <Grid
          container
          direction={sm ? 'column' : 'row'}
          justifyContent="space-between"
          padding={1}
        >
          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignContent="center"
              spacing={1}
            >
              <Grid item container alignItems="center">
                <SmallAvatar alt="avatar" />
                <Typography variant="subtitle2" sx={{ ml: 2 }}>
                  {member.name}
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  variant="standard"
                  placeholder="Age"
                  value={age}
                  onChange={(e) => {
                    setAge(e.target.value)
                    onChangeAge(e.target.value)
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  variant="standard"
                  placeholder="Role"
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value)
                    onChangeRole(e.target.value)
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction={sm ? 'row' : 'column'}
              alignContent="space-between"
              justifyContent="space-between"
            >
              <Grid item>
                <Tooltip title="Move group" placement={sm ? 'top' : 'right'}>
                  <IconButton onClick={onMove}>{chooseIcon()}</IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Change to Admin rank" placement={sm ? 'top' : 'right'}>
                  <IconButton color={getColor()} onClick={onAdmin}>
                    <Star />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Delete User" placement={sm ? 'top' : 'right'}>
                  <IconButton onClick={onDelete}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grow>
  )
}

export default MemberCard
