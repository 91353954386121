import React from 'react'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'

import styled from '@mui/system/styled'

import cloudArt from '../../assets/cloud-photo.png'

const CloudImage = styled('img')`
  width: 100%;
  height: auto;
`

const CloudText = () => {
  return (
    <Container>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <CloudImage src={cloudArt} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" sx={{ mb: 3 }}>
            Never worry about maintenance
          </Typography>
          <Typography variant="subtitle1">
            With automatic cloud scaling, we{"'"}ll make sure your app stays working like a well
            oiled machine at every step. We constantly monitor your app for performance and security
            issues, always ready to jump into action.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default CloudText
