import React from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

import blockArt from '../../assets/block-art.svg'

const LandingSuperContainer = styled('div')({
  color: '#ffffff',
})

const ImageContainer = styled('div')({
  maxWidth: '550px',
})

const Image = styled('img')({
  width: '100%',
  height: 'auto',
})

const ExamplesSuper = () => {
  return (
    <LandingSuperContainer>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        padding={2}
        spacing={4}
        sx={{ pt: 12 }}
      >
        <Grid item maxWidth={500} xs={12} sm={6} lg={3}>
          <Typography variant="h2" gutterBottom>
            Examples
          </Typography>
          <Typography variant="h5" sx={{ color: '#e6e6e6' }}>
            Our previous projects
          </Typography>
        </Grid>
        <Grid item xs={10} sm="auto">
          <ImageContainer>
            <Image src={blockArt} alt="block-art" />
          </ImageContainer>
        </Grid>
      </Grid>
    </LandingSuperContainer>
  )
}

export default ExamplesSuper
